export const VALIDGUESSES = [
"áaður",
"áanna",
"ábæra",
"ábæri",
"ábært",
"Ábali",
"Ábals",
"ábart",
"ábata",
"ábati",
"abban",
"abbar",
"abbin",
"abbum",
"ábend",
"ábera",
"áberi",
"abort",
"ábóta",
"ábrek",
"áburð",
"ábygd",
"ábygt",
"ábyrg",
"aðali",
"aðals",
"Ádami",
"Ádams",
"Adolf",
"ádráp",
"aðrar",
"aðrir",
"æðrað",
"æðrar",
"æðrin",
"æðrum",
"æðrut",
"æðuna",
"ælaði",
"ælaðu",
"æling",
"ælini",
"ælnám",
"æraða",
"æraði",
"æraðu",
"ærast",
"ærdur",
"ærgið",
"ærgir",
"ærgis",
"ærgja",
"ærina",
"ærini",
"ærlig",
"ærnar",
"ærull",
"æruna",
"æruni",
"æruta",
"æsina",
"æsing",
"æsini",
"ætini",
"ætlað",
"ætlan",
"ætlar",
"ættað",
"ættar",
"ættin",
"ættir",
"ættum",
"æviga",
"ævigi",
"ævigt",
"ævigu",
"ævina",
"ævini",
"áfall",
"áfals",
"áfast",
"áfatt",
"áfløt",
"áfoka",
"áfoki",
"áfoks",
"áføll",
"áføst",
"áfram",
"aftan",
"aftna",
"aftra",
"aftri",
"aftur",
"áfylt",
"áfýsn",
"agaði",
"agaðu",
"ágang",
"agans",
"agati",
"agats",
"ágáva",
"ágávu",
"agent",
"ágerð",
"aggað",
"aggar",
"aggið",
"ágiti",
"agnar",
"Agnar",
"Agnas",
"agnið",
"agnum",
"ágóða",
"ágóði",
"ágørn",
"áhald",
"áhøld",
"ahorn",
"áhuga",
"áhugi",
"ajour",
"ájøvn",
"ákæra",
"ákærd",
"ákæri",
"ákært",
"ákæru",
"ákall",
"ákals",
"akarn",
"akast",
"ákast",
"akfar",
"akfør",
"akker",
"ákøll",
"ákoma",
"ákomu",
"akørn",
"ákøst",
"akrar",
"Akrar",
"ákrav",
"akryl",
"Aksal",
"Aksel",
"aksið",
"aksil",
"aksla",
"aksli",
"aktáa",
"aktað",
"aktær",
"aktar",
"aktið",
"aktiv",
"aktóm",
"akurs",
"akutt",
"akveg",
"álaði",
"álaðu",
"álaga",
"álagi",
"álagt",
"Áland",
"alara",
"alari",
"alarm",
"alast",
"álast",
"áláta",
"áláti",
"áláts",
"Albin",
"album",
"aldað",
"aldan",
"aldar",
"aldin",
"aldir",
"aldra",
"aldri",
"aldum",
"aldur",
"aldut",
"áleið",
"Aleks",
"alfús",
"algan",
"algóð",
"algum",
"algur",
"alibi",
"álíka",
"álíkt",
"álina",
"aling",
"áling",
"álini",
"álita",
"áliti",
"álits",
"álkan",
"alkøn",
"álkum",
"álkur",
"allan",
"állan",
"allar",
"állar",
"allík",
"állin",
"allir",
"állum",
"allur",
"állur",
"almar",
"álmar",
"almin",
"álmin",
"álmir",
"almum",
"álmum",
"almur",
"álmur",
"alnær",
"alnet",
"alnir",
"áløga",
"áløgd",
"áløgu",
"álopa",
"álopi",
"álops",
"alrík",
"alrím",
"alrún",
"alska",
"alski",
"alsks",
"alsom",
"altal",
"altan",
"altar",
"altíð",
"altin",
"altir",
"altøk",
"altøl",
"altrá",
"altso",
"altum",
"altur",
"alúna",
"alúni",
"alúns",
"alvað",
"alvæl",
"alvan",
"álvan",
"alvar",
"álvar",
"alvið",
"alvin",
"álvin",
"álvir",
"alvís",
"alvum",
"álvum",
"alvur",
"álvur",
"ámæla",
"ámæli",
"ámælt",
"amara",
"amari",
"amast",
"amber",
"amboð",
"ambra",
"ámint",
"amman",
"ammum",
"ammur",
"ámóta",
"ámóti",
"ámóts",
"ampað",
"ampan",
"ampar",
"amper",
"ampin",
"ampum",
"amtið",
"amtum",
"Ámund",
"amuta",
"ánaði",
"ánaðu",
"ánæmi",
"ánáma",
"ánámi",
"ánáms",
"anara",
"ánara",
"anari",
"ánari",
"andað",
"andan",
"andar",
"andið",
"andin",
"andum",
"andur",
"angað",
"angan",
"angar",
"angin",
"angra",
"angri",
"angum",
"angur",
"ánina",
"aning",
"áning",
"ánini",
"Anita",
"Anitu",
"ankað",
"ankar",
"ankið",
"ankør",
"ankra",
"annað",
"annál",
"annan",
"annar",
"anoda",
"anodu",
"ansað",
"ansar",
"ansið",
"ansin",
"ansna",
"ansni",
"ansum",
"ansur",
"antar",
"antin",
"Anton",
"áogna",
"apaðu",
"apríl",
"apuna",
"apuni",
"aputa",
"araba",
"arabi",
"áræði",
"áraka",
"Árant",
"árása",
"árbók",
"arena",
"árend",
"árent",
"argað",
"argan",
"argar",
"argir",
"Argir",
"Argja",
"argur",
"árina",
"árini",
"árins",
"árita",
"áriti",
"árits",
"arkar",
"arkið",
"arkin",
"arkir",
"arkiv",
"arkum",
"árlig",
"árlóg",
"arman",
"armar",
"armin",
"armir",
"armóð",
"armum",
"armur",
"arnar",
"arnir",
"Arnór",
"árnum",
"árnur",
"árødd",
"áróna",
"árøtt",
"arrið",
"arrum",
"arrut",
"arsar",
"arsen",
"arsin",
"arsum",
"arsur",
"artað",
"artar",
"artin",
"Artur",
"árund",
"árunt",
"arvað",
"arvan",
"arvar",
"arvin",
"arvum",
"arvur",
"ásaka",
"ásamt",
"asans",
"áseta",
"ásett",
"áseyð",
"ásian",
"asiat",
"ásina",
"ásini",
"asins",
"ásins",
"ásium",
"ásiur",
"ásjón",
"askar",
"Áskil",
"askin",
"askir",
"áskot",
"askum",
"askur",
"áslag",
"Áslak",
"ásløg",
"Ásmóð",
"asnan",
"asnar",
"asnin",
"asnum",
"ásnum",
"aspar",
"aspir",
"aspum",
"ástað",
"ástar",
"ástig",
"ástin",
"ástir",
"astma",
"astmu",
"ástóð",
"ástum",
"Ásvør",
"ásýna",
"ásýnt",
"átaka",
"átaki",
"átaks",
"átala",
"átalu",
"átara",
"átari",
"Athen",
"átiga",
"átigt",
"átjan",
"atlas",
"atlát",
"atlim",
"atlit",
"átøka",
"átøkk",
"átøkt",
"átóku",
"atoma",
"atomi",
"atoms",
"átrúa",
"áttum",
"áttur",
"átuta",
"atvik",
"áunum",
"ávara",
"ávari",
"aváti",
"aváts",
"avbær",
"avboð",
"avdal",
"aveld",
"avelt",
"áverk",
"avgap",
"avger",
"avgud",
"ávika",
"áviki",
"áviks",
"avill",
"avilt",
"avísa",
"ávísa",
"ávísi",
"ávíst",
"ávísu",
"avkom",
"avlát",
"avlop",
"avmóð",
"avnor",
"avnór",
"avnot",
"ávøga",
"ávøgu",
"avrik",
"avrit",
"avroð",
"avrók",
"avrot",
"avrúm",
"avsag",
"avtak",
"avtal",
"avtók",
"avveg",
"avvik",
"avvís",
"babba",
"babbi",
"babla",
"babli",
"babls",
"babsa",
"babsi",
"babss",
"baðað",
"baðar",
"báðar",
"baðið",
"báðir",
"baðst",
"baðum",
"báðum",
"bævra",
"bævri",
"bævur",
"bágað",
"bagal",
"bagan",
"bágan",
"bagar",
"bágar",
"bagdi",
"bagdu",
"bagga",
"baggi",
"bágið",
"bagin",
"bágin",
"bagir",
"bagla",
"bagli",
"bágna",
"bagum",
"bágum",
"bakað",
"bakæs",
"bakál",
"bakar",
"bakið",
"bakin",
"bakka",
"bakki",
"bákna",
"bákni",
"bákns",
"baksa",
"baksi",
"baktá",
"bakum",
"bakur",
"bálað",
"balan",
"bálar",
"balda",
"balið",
"bálið",
"balin",
"balja",
"balju",
"balla",
"balli",
"ballu",
"balna",
"balst",
"balta",
"balti",
"bálum",
"bálur",
"bálut",
"bamsa",
"bamsi",
"bamsu",
"banal",
"banan",
"banar",
"banda",
"bandi",
"bands",
"banga",
"bangi",
"bangs",
"banin",
"banka",
"banki",
"banks",
"banna",
"banni",
"banum",
"barað",
"báran",
"barar",
"barda",
"barða",
"bardi",
"barði",
"Bárði",
"barðs",
"bardu",
"barið",
"barka",
"barki",
"barks",
"barma",
"barmi",
"barms",
"barna",
"barni",
"barns",
"barón",
"barra",
"barri",
"barst",
"barum",
"bárum",
"bárur",
"bárut",
"básað",
"basar",
"básar",
"básin",
"baska",
"baski",
"basks",
"bassa",
"bassi",
"basta",
"basti",
"basts",
"bastu",
"básum",
"basun",
"basún",
"básur",
"batað",
"batan",
"batar",
"bátar",
"batin",
"bátin",
"batna",
"batni",
"batta",
"batti",
"battu",
"batum",
"bátum",
"bátur",
"Beata",
"Beatu",
"beðar",
"beddi",
"beðið",
"beðin",
"beðir",
"beðum",
"beður",
"beiða",
"beiði",
"beina",
"Beina",
"beind",
"beini",
"Beini",
"beins",
"beint",
"beinu",
"beisa",
"beisk",
"beist",
"beisu",
"beita",
"beiti",
"beitt",
"beitu",
"bekil",
"bekka",
"bekki",
"bekla",
"bekli",
"bekls",
"belgt",
"belið",
"belis",
"Belis",
"belja",
"belji",
"beljs",
"bella",
"belli",
"belta",
"belti",
"benda",
"bendi",
"bends",
"bendu",
"benið",
"benin",
"Benin",
"benja",
"benum",
"beran",
"berar",
"berár",
"berba",
"berbi",
"berga",
"bergi",
"bergs",
"Bergs",
"berið",
"berir",
"berja",
"berma",
"bermu",
"berst",
"berum",
"berur",
"besta",
"besti",
"bestu",
"betra",
"betri",
"betru",
"Bettý",
"betur",
"beyka",
"beyki",
"beyks",
"bibba",
"bibbi",
"bibbu",
"bíbil",
"bíðað",
"bíðan",
"bíðar",
"bidda",
"biddi",
"biðið",
"bíðið",
"biðil",
"biðin",
"biðir",
"biðis",
"biðja",
"biðji",
"biðla",
"biðli",
"bidna",
"bidnu",
"biðum",
"biður",
"bikað",
"bikar",
"bikið",
"bikka",
"bikku",
"bikør",
"bikum",
"bikut",
"bilað",
"bílæt",
"bílan",
"bilar",
"bilið",
"bílig",
"bilin",
"billa",
"billi",
"billu",
"bíløt",
"bilti",
"biltu",
"bilum",
"bílum",
"bilur",
"bílur",
"binda",
"bindi",
"bíndi",
"binds",
"bíndu",
"binga",
"bingi",
"bingo",
"bings",
"bínir",
"bioum",
"birga",
"Birgi",
"birgt",
"birna",
"Birna",
"Birni",
"birnu",
"birta",
"birti",
"birtu",
"bison",
"bispa",
"bispi",
"bisps",
"bitan",
"bitar",
"bítar",
"bitið",
"bítið",
"bitil",
"bitin",
"bítin",
"bitna",
"bitni",
"bitra",
"bítsk",
"bitum",
"bítum",
"bitur",
"bítur",
"bitut",
"bivað",
"bivan",
"bivar",
"bivið",
"bjarg",
"bjart",
"bjóða",
"bjóði",
"bjølg",
"bjøll",
"bjóra",
"bjørg",
"bjóri",
"bjørk",
"bjørn",
"bjórs",
"bjørt",
"bláan",
"bláar",
"blaða",
"blaði",
"blaðs",
"blæan",
"blæma",
"blæmu",
"blæsa",
"blæum",
"blæur",
"bláin",
"bláir",
"blaka",
"blaki",
"blakk",
"blaks",
"bláma",
"blána",
"bland",
"bláni",
"blarv",
"blása",
"blási",
"blást",
"blátt",
"bláum",
"bláur",
"bleik",
"bleiv",
"blekk",
"bleks",
"blets",
"blett",
"bleyt",
"blíða",
"blíði",
"blíðu",
"blika",
"bliki",
"blikk",
"bliks",
"bliku",
"blind",
"blint",
"blits",
"blítt",
"blíva",
"blívi",
"blivu",
"blóða",
"bløða",
"blødd",
"blóði",
"bløði",
"blóðs",
"blogg",
"blogs",
"bløka",
"blokk",
"bloks",
"bløku",
"blóma",
"blømd",
"blómi",
"blømt",
"blómu",
"blonk",
"blota",
"blóta",
"bloti",
"blóti",
"blots",
"blóts",
"bløtt",
"blues",
"blúgt",
"blúgv",
"blund",
"blúni",
"blunk",
"blusa",
"bluss",
"blusu",
"blygd",
"blýið",
"bobba",
"bobbi",
"bøbit",
"boðað",
"boðan",
"boðar",
"boðið",
"bøðil",
"boðin",
"bøðla",
"bøðli",
"bodna",
"bodnu",
"boðum",
"bogað",
"bøgæs",
"bogan",
"bøgan",
"bogar",
"bøgás",
"bogið",
"bogin",
"bøgir",
"bogna",
"bogni",
"bognu",
"bogra",
"bogum",
"bøgum",
"bøgur",
"bógva",
"bógvi",
"bókað",
"bókan",
"bókar",
"bøkil",
"bókin",
"bøkir",
"bøkja",
"bøkki",
"bøkla",
"bøkli",
"boksa",
"boksl",
"bøksl",
"bøkti",
"bøktu",
"bøkul",
"bókum",
"bøkum",
"bøkur",
"bøkut",
"bolað",
"bólað",
"bølað",
"bolar",
"bólar",
"bølar",
"bøldi",
"bøldu",
"bólið",
"bølið",
"bølir",
"bølis",
"bólka",
"bólki",
"bólks",
"bolla",
"bølla",
"bolli",
"bølli",
"bólna",
"bolta",
"bólta",
"bolti",
"bólti",
"bolts",
"bólts",
"bólum",
"bølum",
"bølva",
"bomma",
"bommi",
"bonað",
"bønað",
"bønan",
"bonar",
"bønar",
"bónda",
"bóndi",
"bønin",
"bønir",
"bonki",
"bonsk",
"bønum",
"bønur",
"bonus",
"boppa",
"boppu",
"borað",
"børan",
"borar",
"borda",
"borða",
"bordi",
"borði",
"borðs",
"borga",
"borgi",
"borgs",
"borið",
"borin",
"borir",
"børka",
"børki",
"børku",
"borna",
"borum",
"børum",
"borur",
"børur",
"bosað",
"bosar",
"bosið",
"bosni",
"bosut",
"bótað",
"bøtan",
"bótar",
"bótin",
"bøtir",
"botna",
"botni",
"botns",
"bøtta",
"bøtti",
"bøttu",
"bótum",
"bøtur",
"bótut",
"boyan",
"boygd",
"boygt",
"boyla",
"boyli",
"boysa",
"boysi",
"boyum",
"boyur",
"bráað",
"bráan",
"bráar",
"bráða",
"bráði",
"bráðu",
"bræða",
"brædd",
"bræði",
"brætt",
"bræva",
"brævi",
"brævs",
"bragd",
"bragð",
"bragg",
"brags",
"bráið",
"bráin",
"bráir",
"braka",
"bráka",
"braki",
"bráki",
"braks",
"bráks",
"brala",
"brali",
"brals",
"brand",
"Brand",
"brann",
"brast",
"bratt",
"brátt",
"bráum",
"bráur",
"bregd",
"bregs",
"breið",
"breka",
"breki",
"breks",
"brell",
"brels",
"brend",
"brenn",
"brent",
"brest",
"breta",
"breti",
"brett",
"breyð",
"breyt",
"brigd",
"brigg",
"bríka",
"brima",
"brimi",
"brims",
"bring",
"brint",
"brisa",
"brisi",
"britu",
"brodd",
"brøgd",
"brøgð",
"bróka",
"brokk",
"broks",
"brøla",
"brøli",
"brøls",
"bromi",
"broms",
"brosa",
"brosi",
"brósk",
"bróst",
"brota",
"bróta",
"broti",
"bróti",
"brots",
"brøtt",
"broyt",
"brúar",
"brúgv",
"brúin",
"brúka",
"brúki",
"brúks",
"brúkt",
"brumm",
"brums",
"bruna",
"brúna",
"brund",
"bruni",
"brúni",
"brunn",
"bruns",
"brúnt",
"brúnu",
"brúsa",
"brúsi",
"brúsu",
"brutt",
"brutu",
"brygs",
"brýna",
"brýnd",
"brýni",
"brynt",
"brýnt",
"bryst",
"búðar",
"búðin",
"búðir",
"búðum",
"búfæi",
"búffa",
"búffi",
"búfla",
"búfli",
"búfør",
"bugað",
"bugar",
"bugin",
"bugum",
"bugur",
"búgva",
"búgvi",
"bukað",
"bukan",
"bukar",
"búkar",
"búkin",
"bukka",
"bukki",
"bukku",
"bukla",
"bukli",
"buklu",
"buksa",
"bukta",
"búkum",
"búkur",
"búkut",
"bulað",
"bular",
"bulin",
"bulka",
"bulki",
"bulks",
"bulla",
"bulli",
"bullu",
"bulur",
"búlut",
"bumba",
"bumbi",
"bumbu",
"bumma",
"bummi",
"bumsa",
"bumsi",
"búnað",
"bunan",
"búnan",
"búnar",
"bunda",
"bundi",
"bundu",
"bunga",
"bungu",
"búnin",
"búnir",
"bunit",
"bunka",
"bunki",
"bunta",
"bunti",
"bunum",
"búnum",
"bunur",
"búogn",
"búøki",
"buppa",
"buppu",
"burði",
"búrið",
"Burma",
"búrók",
"búrum",
"búrúm",
"busað",
"busar",
"búsíl",
"buski",
"busks",
"bussa",
"bússa",
"bussi",
"bussu",
"bússu",
"busta",
"busut",
"butan",
"Butan",
"bútíð",
"butta",
"butti",
"búveg",
"býboð",
"býðst",
"býður",
"bygda",
"bygdi",
"bygdu",
"byggi",
"býggi",
"býggj",
"býlið",
"býlir",
"býlis",
"býlum",
"bylur",
"býnum",
"býráð",
"byrða",
"byrði",
"byrðu",
"byrgd",
"byrgi",
"byrgt",
"byrin",
"byrja",
"byrji",
"byrsa",
"byrsu",
"byrur",
"býtið",
"býtir",
"býtis",
"bytta",
"býtta",
"býtti",
"byttu",
"býttu",
"býtum",
"bývák",
"cand.",
"cello",
"cents",
"chips",
"dáans",
"dáðar",
"dadda",
"daddu",
"dáðin",
"dáðir",
"dadla",
"dadlu",
"dáðum",
"dádýr",
"dælir",
"dælla",
"dælur",
"dæmda",
"dæmið",
"dæmis",
"daffa",
"dagað",
"dagar",
"dagf.",
"dagin",
"Dagný",
"dagur",
"dakar",
"dakka",
"dalað",
"dálan",
"dalar",
"dálar",
"dalda",
"dalin",
"dálin",
"dalir",
"dálka",
"dálki",
"dalla",
"dalli",
"dalsá",
"dalum",
"dálum",
"dalur",
"Dalur",
"dámað",
"daman",
"dámar",
"dámda",
"dámdi",
"dámdu",
"dámin",
"damla",
"damli",
"damls",
"damma",
"dammi",
"dammu",
"dampa",
"dampi",
"damps",
"damum",
"damur",
"dámur",
"dánað",
"danan",
"dánan",
"dánar",
"danda",
"dandi",
"dandu",
"danga",
"dangu",
"Dania",
"danin",
"dánin",
"danir",
"dánir",
"Daniu",
"dansa",
"dansi",
"dansk",
"dánum",
"dánur",
"dapað",
"dapar",
"dapra",
"dapri",
"dapur",
"dárað",
"dáran",
"dárar",
"dárin",
"darla",
"darli",
"darra",
"darri",
"darta",
"darti",
"darts",
"dárum",
"darva",
"dásan",
"dásar",
"dásin",
"dassa",
"dassi",
"dasti",
"dasts",
"dásum",
"dativ",
"datst",
"datum",
"david",
"Dávid",
"Dávið",
"Dávur",
"debet",
"debut",
"degna",
"deiga",
"deigs",
"deigu",
"deila",
"deild",
"deils",
"deilt",
"deilu",
"dekan",
"dekil",
"dekka",
"dekki",
"dekla",
"dekli",
"dekna",
"dekni",
"dekns",
"delda",
"Delhi",
"demdi",
"demdu",
"demma",
"denti",
"dents",
"depil",
"Depil",
"depla",
"depli",
"Depli",
"desar",
"desin",
"desir",
"desum",
"detta",
"detti",
"devil",
"devla",
"devli",
"devul",
"deyda",
"deyða",
"deyði",
"deydu",
"deyðu",
"deyma",
"deymi",
"deyms",
"deysa",
"deytt",
"deyva",
"deyvi",
"deyvu",
"Diana",
"Dianu",
"didda",
"diddu",
"digga",
"diggu",
"digla",
"diglu",
"digra",
"digur",
"dikið",
"díkið",
"díkir",
"díkis",
"díkja",
"dikka",
"dikst",
"díkti",
"díktu",
"dilka",
"dilki",
"dilla",
"dimdi",
"dimdu",
"dimma",
"dimmi",
"dimmu",
"dímun",
"dinta",
"dinti",
"dints",
"dioda",
"diodu",
"Dioni",
"Dions",
"dirka",
"dirki",
"dirks",
"dirva",
"dirvi",
"dísar",
"dísin",
"disja",
"diska",
"diski",
"disko",
"disks",
"disma",
"dismi",
"disms",
"dispi",
"disps",
"dísum",
"ditna",
"ditta",
"ditti",
"ditto",
"dittu",
"divan",
"djass",
"Djóna",
"Djóni",
"djóra",
"djóri",
"djørv",
"djúpa",
"djúpd",
"djúpi",
"djúps",
"djúpt",
"djúpu",
"djúsi",
"dnaið",
"dnaum",
"dobba",
"dobbi",
"doðað",
"doðan",
"doðar",
"dodda",
"doddi",
"doðin",
"døgga",
"døgum",
"dokka",
"døkka",
"dokki",
"døkki",
"døkku",
"dolka",
"dolki",
"dolks",
"dølla",
"dølli",
"dølma",
"dølsk",
"dølum",
"dómar",
"dømda",
"dømdi",
"dømdu",
"dømið",
"dómin",
"dømir",
"dømis",
"dómum",
"dømum",
"dómur",
"Donau",
"dongt",
"donor",
"donsk",
"dønum",
"dópar",
"dópin",
"døpru",
"døpul",
"dópum",
"dópur",
"døpur",
"dorga",
"døsan",
"dósar",
"døsar",
"dósið",
"døsið",
"døsil",
"dósin",
"døsin",
"dósir",
"døsla",
"døsli",
"dósna",
"døsna",
"dossa",
"dósum",
"døsum",
"dósut",
"døtla",
"døtra",
"døtti",
"døtur",
"Dover",
"dovið",
"dovin",
"dovna",
"dovnu",
"doydd",
"doyði",
"doyðs",
"doyðu",
"doyin",
"doypa",
"doypi",
"doypt",
"doyrt",
"doyva",
"doyvd",
"doyvt",
"drabb",
"drabs",
"draga",
"dragi",
"drags",
"drakk",
"drakt",
"drála",
"dráli",
"dráls",
"drama",
"dramm",
"drams",
"dramu",
"drápa",
"drápi",
"drapp",
"draps",
"dráps",
"drápu",
"drasl",
"dratl",
"drátt",
"dravi",
"dravs",
"dregg",
"dregs",
"dreit",
"dreiv",
"dreka",
"dreki",
"drekk",
"drekt",
"drena",
"dreni",
"drens",
"drepa",
"drepi",
"dreya",
"dreym",
"dreyp",
"drift",
"dríta",
"driti",
"dríti",
"drits",
"dríts",
"dritu",
"dríva",
"drivi",
"drívi",
"drivs",
"drivu",
"drøgg",
"drøgs",
"drógu",
"drong",
"dropa",
"dropi",
"drops",
"drópu",
"drosl",
"dróst",
"drots",
"drott",
"droym",
"drúgt",
"drúgv",
"drumb",
"drunn",
"druns",
"drúpa",
"drupu",
"drúva",
"drúvu",
"drykk",
"drýla",
"drýli",
"drýls",
"drynj",
"drýpa",
"drýpi",
"drypp",
"dryps",
"drýpt",
"dubai",
"dubba",
"dudda",
"duett",
"duffa",
"dugað",
"dugdi",
"dugdu",
"dugga",
"dugin",
"dugir",
"dugna",
"dugni",
"dugur",
"dúgva",
"dúgvu",
"dukað",
"dúkað",
"dukan",
"dukar",
"dúkar",
"dukat",
"dúkin",
"dukka",
"dukku",
"dukum",
"dúkum",
"dúkur",
"dulan",
"dular",
"dulda",
"duldi",
"duldu",
"dulin",
"dulir",
"dulla",
"dulum",
"dulur",
"duman",
"dumba",
"dumbt",
"dumbu",
"dumma",
"dummi",
"dummu",
"dumpa",
"dumsa",
"dumsi",
"dunað",
"dúnað",
"dunan",
"dunar",
"dúnar",
"dundi",
"dundu",
"dunga",
"dungi",
"dunið",
"dúnið",
"dunka",
"dunki",
"dunks",
"dunna",
"dunnu",
"dúnut",
"dupla",
"dupli",
"dupls",
"dupul",
"dúrað",
"durar",
"dúrar",
"durin",
"dúrin",
"dúrka",
"dúrki",
"dúrks",
"durum",
"dúrum",
"durur",
"dúrur",
"durva",
"dusað",
"dúsað",
"dúsan",
"dusar",
"dúsar",
"dusin",
"dúsin",
"dúsir",
"duska",
"duski",
"dusks",
"dusma",
"dusmi",
"dusms",
"dusta",
"dusti",
"dusts",
"dúsum",
"dúsur",
"dútta",
"duttu",
"dúvað",
"dúvan",
"dúvar",
"dúvum",
"dúvur",
"dvala",
"dvali",
"dvalt",
"dveyl",
"dvína",
"dvínt",
"dvølt",
"dvørg",
"dygda",
"dygga",
"dýggi",
"dýggj",
"dyggu",
"dygst",
"dýkir",
"dýkja",
"dýkti",
"dýktu",
"dylja",
"dylji",
"dylla",
"dyllu",
"dylst",
"dylur",
"dymba",
"dymbi",
"dymbs",
"dýnan",
"dyngt",
"dynja",
"dýnum",
"dynur",
"dýnur",
"dýpda",
"dýpið",
"dýpir",
"dýpis",
"dyppa",
"dyppi",
"dýpti",
"dýptu",
"dýpum",
"dýrað",
"dýran",
"dýrar",
"dýrda",
"dyrgt",
"dýrið",
"dýrir",
"dyrka",
"dýrka",
"dyrki",
"dýrum",
"dýrur",
"dysan",
"dysta",
"dysti",
"dysum",
"dysur",
"dývát",
"dývdi",
"dývdu",
"dývir",
"e.Kr.",
"ediki",
"ediks",
"edrúa",
"edrúi",
"Edvin",
"eftir",
"eggið",
"eggin",
"eggir",
"eggja",
"eggum",
"Egili",
"Egils",
"egnað",
"egnan",
"egnar",
"egndi",
"egndu",
"egnir",
"egnum",
"eiðar",
"eiðið",
"eiðin",
"eiðir",
"eiðis",
"Eiðis",
"eiðum",
"eiður",
"eigan",
"eigið",
"eigir",
"eigum",
"eigur",
"eikar",
"eikin",
"eikir",
"eikja",
"eikju",
"eikum",
"Eilin",
"eimin",
"eimur",
"einar",
"einda",
"eingi",
"einið",
"einir",
"einis",
"einki",
"einst",
"einta",
"einum",
"eirdi",
"eirdu",
"eirið",
"Eirik",
"eirin",
"eirir",
"eirna",
"eista",
"eistu",
"eitið",
"eitil",
"eitir",
"eitis",
"eitla",
"eitli",
"eitra",
"eitri",
"eitum",
"eitur",
"ekkóa",
"ekkói",
"ekkós",
"eklað",
"eklar",
"ekran",
"ekrum",
"ekrur",
"eksam",
"eksem",
"ektað",
"ektar",
"eldað",
"eldan",
"eldar",
"eldin",
"eldir",
"eldra",
"eldri",
"eldru",
"eldum",
"eldur",
"elgar",
"elgdi",
"elgdu",
"elgið",
"elgin",
"elgir",
"elgja",
"elgum",
"elgur",
"Elias",
"Elina",
"Elini",
"Elisa",
"Elisi",
"Elisu",
"eljan",
"eljum",
"eljur",
"ellin",
"ellis",
"Elmar",
"elrør",
"Elsba",
"Elsbu",
"elska",
"elski",
"Elspa",
"Elspu",
"elsta",
"elsti",
"elstu",
"eltan",
"eltið",
"eltin",
"eltir",
"eltna",
"eltur",
"elvar",
"Elvar",
"elvdi",
"elvdu",
"elvin",
"elvir",
"elvum",
"elvur",
"embar",
"embør",
"Emili",
"Emils",
"Emilý",
"emira",
"emiri",
"emirs",
"endað",
"endan",
"endar",
"endin",
"endir",
"endum",
"endur",
"ennið",
"ennir",
"ennis",
"enntá",
"enntó",
"ennum",
"Enoki",
"Enoks",
"enska",
"enski",
"enskt",
"ensku",
"entir",
"entra",
"entur",
"episk",
"eplið",
"eplir",
"eplis",
"eplum",
"eplut",
"erfín",
"Erika",
"Eriku",
"erlan",
"erlig",
"erlum",
"erlur",
"ermaa",
"erman",
"ermar",
"ermin",
"ermum",
"ermur",
"ernan",
"ernar",
"ernir",
"Ernst",
"ernum",
"erpað",
"erpar",
"erpið",
"erpin",
"erpna",
"erpni",
"errað",
"errar",
"errið",
"errin",
"errum",
"ertað",
"ertið",
"ertin",
"ertir",
"ertna",
"ertra",
"ertur",
"ervað",
"ervar",
"ervdi",
"ervdu",
"ervið",
"ervir",
"ervis",
"ervum",
"esils",
"esjað",
"esjan",
"esjar",
"Eskil",
"eskja",
"eskju",
"eslið",
"eslum",
"Esmar",
"espar",
"espin",
"espir",
"espum",
"essið",
"essum",
"estan",
"estar",
"Ester",
"estin",
"estum",
"etast",
"etikk",
"etiks",
"eting",
"etisk",
"etjað",
"etjar",
"etnar",
"etnir",
"etrið",
"eturs",
"Evald",
"evars",
"evjað",
"evjan",
"evjar",
"evjut",
"evlið",
"evlis",
"evnað",
"evnar",
"evndi",
"evndu",
"evnið",
"evnir",
"evnum",
"evran",
"evrum",
"evrur",
"evsta",
"evsti",
"evstu",
"eyðan",
"eyðar",
"eyðið",
"eyðin",
"eyðir",
"eydna",
"eydnu",
"eyðum",
"Eyðun",
"eyður",
"eyeya",
"eygað",
"eygna",
"eygum",
"eygur",
"eygut",
"eykað",
"eykan",
"eykar",
"eykin",
"eykum",
"eykut",
"eyman",
"eymar",
"eymir",
"eymka",
"eymki",
"eymks",
"eymum",
"eymur",
"eyrin",
"eyrur",
"eyrut",
"f.Kr.",
"fáari",
"fáast",
"faðir",
"fæhus",
"fækka",
"fælut",
"fænum",
"færra",
"færri",
"fæstu",
"fafta",
"fagna",
"fagra",
"fagri",
"fagur",
"fáist",
"fakið",
"faksa",
"faksi",
"fakta",
"fakum",
"falan",
"falar",
"fálát",
"falda",
"faldi",
"falds",
"fálig",
"falin",
"falir",
"falka",
"fálka",
"falki",
"fálki",
"falks",
"fálks",
"falla",
"falli",
"fálma",
"falna",
"falni",
"falnu",
"falsa",
"falsi",
"falsk",
"falum",
"falur",
"fámað",
"fámar",
"fánað",
"fanan",
"fánan",
"fanar",
"fánar",
"fanga",
"fangi",
"fangs",
"fánið",
"fanin",
"fánin",
"fanna",
"Fanný",
"fansa",
"fansi",
"fanst",
"fanta",
"fanum",
"fánum",
"fányt",
"fánýt",
"farga",
"fargi",
"fargs",
"farið",
"fárið",
"farin",
"farma",
"farmi",
"farms",
"farna",
"farni",
"farnu",
"farra",
"farri",
"farru",
"farsa",
"farsi",
"farta",
"farti",
"farts",
"fárum",
"fasan",
"Faso ",
"fasta",
"fasti",
"fatað",
"fátæk",
"fatan",
"fatar",
"fatið",
"fatla",
"fatli",
"fatls",
"fatna",
"fátøk",
"fatøl",
"fatta",
"fatti",
"fattu",
"fatur",
"favna",
"favni",
"favns",
"feðga",
"fedra",
"fegið",
"fegin",
"fegna",
"feiað",
"feiar",
"feiga",
"feigd",
"feigð",
"feigt",
"feila",
"feili",
"feils",
"feilt",
"feima",
"feita",
"feiti",
"feits",
"feitt",
"feitu",
"fekst",
"fekta",
"felag",
"felan",
"felar",
"felda",
"feldi",
"felds",
"feldu",
"felga",
"felgu",
"felin",
"fella",
"felli",
"fellu",
"feløg",
"felti",
"felum",
"fenað",
"fenan",
"fenar",
"fenið",
"fenin",
"fenir",
"fenis",
"fenja",
"fenol",
"fenum",
"fepri",
"fepur",
"ferða",
"ferga",
"fergu",
"feria",
"feril",
"feriu",
"ferja",
"ferju",
"ferli",
"ferma",
"fermd",
"fermt",
"ferst",
"fesil",
"fesja",
"fesji",
"fesjs",
"fesju",
"feska",
"feski",
"feskt",
"fesku",
"fesla",
"fesli",
"festa",
"festi",
"fests",
"festu",
"fetað",
"fetan",
"fetar",
"fetið",
"fetil",
"fetin",
"fetla",
"fetli",
"fetta",
"fetti",
"fettu",
"fetum",
"fevna",
"fevnd",
"fevni",
"fevnt",
"Fidji",
"fiðri",
"figur",
"fikan",
"fikka",
"fikku",
"fiksa",
"fikst",
"fikta",
"fikum",
"fikur",
"filað",
"fílað",
"fílan",
"filar",
"fílar",
"fílið",
"fílin",
"Filip",
"fílir",
"filla",
"filma",
"filmi",
"films",
"filpa",
"filpi",
"filps",
"fílum",
"fílur",
"fiman",
"fimar",
"fimið",
"fimir",
"fimis",
"fimmu",
"fimna",
"fimta",
"fimti",
"fimtu",
"fimum",
"fimur",
"fínan",
"fínar",
"fingu",
"fínir",
"finka",
"fínka",
"finna",
"finni",
"finnu",
"finsk",
"finst",
"finta",
"fintu",
"fínum",
"fínur",
"fióla",
"fipan",
"fípan",
"firði",
"fírdi",
"fírdu",
"fírir",
"firma",
"firmu",
"firra",
"firru",
"firta",
"fisan",
"físan",
"físar",
"físið",
"físin",
"físir",
"fiska",
"fiski",
"fisks",
"físti",
"fístu",
"fisum",
"físum",
"fisur",
"físur",
"fitað",
"fitar",
"fitin",
"fitja",
"fitju",
"fitla",
"fitlu",
"fitna",
"fitta",
"fitti",
"fittu",
"fívil",
"fívla",
"fívli",
"fjáka",
"fjáki",
"fjáks",
"fjala",
"fjald",
"fjálg",
"fjali",
"fjall",
"fjals",
"fjalt",
"fjant",
"fjara",
"fjart",
"fjaru",
"fjasa",
"fjasi",
"fjøgg",
"fjøgs",
"fjóla",
"fjøld",
"fjóli",
"fjøll",
"fjóls",
"fjólu",
"fjóna",
"fjóni",
"Fjóni",
"fjóns",
"Fjóns",
"fjøra",
"fjørð",
"fjøru",
"fjósa",
"fjøsa",
"fjósi",
"fjøsi",
"fjøss",
"fjúga",
"fjúsa",
"fjúsi",
"fláað",
"fláan",
"fláar",
"flæir",
"flæsa",
"flæst",
"flætt",
"flaga",
"flagd",
"flagg",
"flagi",
"flags",
"fláin",
"fláir",
"flaka",
"flaki",
"flakk",
"flákr",
"flaks",
"flakt",
"flána",
"flans",
"flata",
"flati",
"flatt",
"flátt",
"fláum",
"fláur",
"fleir",
"flekk",
"flenn",
"flens",
"flent",
"flesa",
"flesk",
"flest",
"flett",
"fleyg",
"fleyr",
"fleys",
"fleyt",
"fliða",
"fliðu",
"flika",
"fliku",
"flími",
"flíms",
"flint",
"flipp",
"flips",
"flísa",
"fljóð",
"fljót",
"flóða",
"fløða",
"flóði",
"flóðs",
"fløga",
"fløgd",
"fløgg",
"flogi",
"fløgu",
"flógv",
"floka",
"flóka",
"floki",
"flóki",
"fløki",
"flokk",
"floks",
"flóks",
"fløkt",
"flóna",
"flong",
"flóni",
"flora",
"flóra",
"flóri",
"flórs",
"floru",
"flota",
"flóta",
"fløta",
"floti",
"flóti",
"fløti",
"flots",
"fløts",
"flott",
"flótt",
"fløtt",
"fløtu",
"fløva",
"fløvi",
"floya",
"floyi",
"floym",
"floys",
"floyt",
"fluga",
"flugu",
"flúgv",
"flukt",
"fluor",
"flúra",
"flúri",
"flúrs",
"flusa",
"flúsa",
"flusi",
"flust",
"flutt",
"flutu",
"flýdd",
"flysa",
"flyta",
"flyti",
"flytt",
"flýtt",
"fnisa",
"fnisi",
"fødda",
"føddi",
"føddu",
"føðga",
"føðin",
"føðir",
"fóðra",
"fóðri",
"fóður",
"føgru",
"føgur",
"fokið",
"fokin",
"fokka",
"fokku",
"fokna",
"fokum",
"fokus",
"folað",
"folan",
"folar",
"folda",
"foldi",
"føldi",
"folds",
"føldu",
"følið",
"folin",
"følin",
"folio",
"følir",
"følis",
"fólka",
"fólki",
"fólks",
"følna",
"fólsk",
"følsk",
"folum",
"følum",
"følva",
"følvi",
"følvs",
"fómað",
"fómar",
"fómur",
"fongd",
"fongi",
"fongs",
"fongt",
"fonið",
"fonum",
"forað",
"foran",
"fóran",
"føran",
"forar",
"førar",
"forða",
"førda",
"førdi",
"førdu",
"førið",
"forin",
"førir",
"føris",
"førka",
"form.",
"forma",
"formi",
"forms",
"forna",
"førna",
"forni",
"fornt",
"fornu",
"fórst",
"forta",
"forum",
"førum",
"forur",
"førur",
"forút",
"føsil",
"føsla",
"føsli",
"fossa",
"fossi",
"føsta",
"føstu",
"fótað",
"fótar",
"føtil",
"fótin",
"føtla",
"føtli",
"føtta",
"fótum",
"føtum",
"fótur",
"føtur",
"foyan",
"foyar",
"foyir",
"foyra",
"foyru",
"foysa",
"foyst",
"foytt",
"foyum",
"foyur",
"fræað",
"fræar",
"fræga",
"frægd",
"frægi",
"frægt",
"frægu",
"fræið",
"fræls",
"fræna",
"fræsa",
"fræst",
"frætt",
"fræum",
"fragd",
"frakt",
"frama",
"framá",
"framd",
"frami",
"framí",
"framt",
"frank",
"Frank",
"frans",
"Frans",
"frasi",
"frata",
"fregn",
"freka",
"freki",
"frekt",
"frell",
"frels",
"frens",
"freon",
"fresa",
"fress",
"frest",
"freta",
"freti",
"frets",
"fríað",
"frían",
"fríar",
"fríár",
"Frida",
"friða",
"fríða",
"friði",
"fríði",
"Fridu",
"fríðu",
"fríið",
"fríir",
"frísa",
"frísi",
"frísk",
"frísu",
"Frits",
"fritt",
"frítt",
"fríum",
"fríur",
"fróan",
"fróar",
"froða",
"fróða",
"frøða",
"froði",
"frøði",
"froðu",
"frøin",
"fróir",
"frøir",
"fróma",
"frómt",
"front",
"frosk",
"frost",
"frótt",
"frøtt",
"fróum",
"fróur",
"froyð",
"froys",
"frúan",
"frúar",
"frúgv",
"frúin",
"frúir",
"frukt",
"frúna",
"frúni",
"frunt",
"frúum",
"frúur",
"frykt",
"fryns",
"frýnt",
"fryst",
"fubba",
"fubbi",
"fugað",
"fugan",
"fugar",
"fugla",
"fugli",
"fugls",
"fugum",
"fugur",
"fúgva",
"fúgvi",
"fukka",
"fúlan",
"fúlar",
"fúlir",
"fulla",
"fulli",
"fullu",
"fulpa",
"fulpi",
"fulps",
"fúlum",
"fúlur",
"fumma",
"fummu",
"fúnað",
"fúnan",
"fúnar",
"funda",
"fundi",
"funds",
"fúnir",
"funka",
"funku",
"funnu",
"funsa",
"funsi",
"funta",
"funti",
"funts",
"fúnum",
"furað",
"furan",
"furar",
"furða",
"furðu",
"furum",
"furur",
"fúsan",
"fúsar",
"fusin",
"fúsin",
"fúsir",
"fuski",
"fussa",
"fussi",
"fúsum",
"fúsur",
"fútan",
"fútar",
"fútin",
"futti",
"fútum",
"fuvað",
"fuvan",
"fuvar",
"fygla",
"fyglt",
"fýkur",
"fylgd",
"fylgi",
"fylgt",
"fylið",
"fylja",
"fylki",
"fylkt",
"fylla",
"fylli",
"fyllu",
"fylri",
"fýlst",
"fylti",
"fyltu",
"fylum",
"fýrar",
"fýrið",
"fýrik",
"fýrin",
"fyrna",
"fýrna",
"fyrnd",
"fyrni",
"fyrnt",
"fyrra",
"fyrri",
"fyrru",
"fyrrv",
"fyrst",
"fýrum",
"fýrur",
"fýsir",
"fýsni",
"fýsta",
"fýsti",
"fýstu",
"gáaði",
"gáaðu",
"gabba",
"gabbi",
"Gabon",
"gadda",
"gaddi",
"gáddi",
"gáddu",
"gærað",
"gærar",
"gærdi",
"gærdu",
"gærin",
"gærir",
"gærna",
"gærut",
"gæsað",
"gæsan",
"gæsar",
"gæsið",
"gæsin",
"gæsna",
"gæsni",
"gæsum",
"gæsur",
"gæsut",
"gætað",
"gætar",
"gætir",
"gætti",
"gættu",
"gætur",
"gævan",
"gævig",
"gævur",
"gafli",
"gagga",
"gágga",
"gaggu",
"gággu",
"gagna",
"gagni",
"gagns",
"gákað",
"gákan",
"gákar",
"gákið",
"gákin",
"gákum",
"gákur",
"gákut",
"galda",
"gálga",
"gálgi",
"galið",
"galin",
"galla",
"galli",
"gallu",
"galta",
"galti",
"galts",
"galum",
"galva",
"gálva",
"galvi",
"gálvi",
"galvs",
"gálvs",
"gamal",
"gaman",
"gambi",
"gamla",
"gamli",
"gamma",
"gammi",
"gamni",
"gánað",
"gánan",
"gánar",
"ganda",
"gandi",
"gands",
"ganga",
"gangi",
"gangs",
"gánið",
"ganir",
"gánir",
"ganum",
"gánum",
"gánus",
"gapað",
"gapar",
"gapið",
"gapum",
"garða",
"garði",
"garðs",
"garma",
"garmi",
"garms",
"garna",
"garni",
"garns",
"garpa",
"garpi",
"garps",
"garta",
"garti",
"garts",
"garva",
"gásar",
"gásin",
"gassa",
"gassi",
"gásum",
"gatað",
"gatan",
"gátan",
"gatar",
"gatið",
"gatum",
"gátum",
"gatur",
"gátur",
"gávað",
"gávan",
"gávis",
"gavsa",
"gavsi",
"gavst",
"gávum",
"gávur",
"gávus",
"geara",
"geari",
"gears",
"gedda",
"geddu",
"gefoy",
"gegla",
"gegli",
"gegls",
"gegna",
"gegni",
"geipa",
"geipi",
"geips",
"geira",
"geiri",
"geisa",
"geisp",
"geita",
"gekka",
"gekki",
"gekst",
"gelda",
"geldi",
"geldu",
"gelið",
"gella",
"gelti",
"geltu",
"gelum",
"gelur",
"gemsa",
"gemsu",
"genað",
"genar",
"genið",
"genta",
"gentu",
"genum",
"Georg",
"gerað",
"geran",
"gerar",
"gerða",
"gerdi",
"gerði",
"gerdu",
"gerið",
"geril",
"gerin",
"gerla",
"gerli",
"gerst",
"gerut",
"gerva",
"gervi",
"gesna",
"gessi",
"gesti",
"gests",
"gevar",
"gevið",
"gevst",
"gevur",
"geyka",
"geyki",
"geykn",
"geyks",
"geyla",
"geyli",
"geyls",
"geyma",
"geymi",
"geyms",
"geypa",
"geypu",
"geyra",
"geyri",
"geyrs",
"gidda",
"gifta",
"gifti",
"giftu",
"gíggi",
"gíggj",
"gigla",
"gilda",
"gildi",
"gilið",
"gilja",
"gilla",
"Gilla",
"Gilli",
"gillu",
"gilsa",
"gilsi",
"gimað",
"giman",
"gimar",
"gimin",
"ginað",
"ginan",
"ginar",
"gingu",
"ginna",
"ginni",
"ginti",
"gintu",
"gippa",
"gipsa",
"gipsi",
"girað",
"girar",
"girða",
"girdi",
"girði",
"girdu",
"girið",
"gírig",
"girnd",
"gírni",
"giroa",
"gírug",
"girum",
"gisað",
"gisar",
"gisið",
"gisin",
"gísla",
"gísli",
"gisna",
"gissa",
"gista",
"gisti",
"gistu",
"gitan",
"gitar",
"gitið",
"gitin",
"gitir",
"gitis",
"gitna",
"gitni",
"gitnu",
"gitta",
"gitti",
"gittu",
"gitur",
"givið",
"givin",
"givna",
"givnu",
"gívra",
"gívur",
"gjáar",
"Gjáar",
"gjáin",
"gjáir",
"gjala",
"gjald",
"gjálv",
"gjara",
"gjari",
"gjars",
"gjáum",
"gjaur",
"gjógv",
"Gjógv",
"gjóin",
"gjøld",
"gjóna",
"gjóni",
"gjøra",
"gjørd",
"gjørð",
"gjørs",
"gjørt",
"gjósa",
"gjóta",
"gjótu",
"gjums",
"glaða",
"glaði",
"glaðu",
"glæma",
"glæmt",
"glæmu",
"glæra",
"glært",
"glæru",
"glæsa",
"glæst",
"glamm",
"glamp",
"glams",
"glana",
"glans",
"glant",
"glapp",
"glara",
"glari",
"glars",
"glasa",
"glasi",
"glata",
"glatt",
"gleða",
"gleði",
"glefs",
"gleið",
"gleim",
"gleiv",
"glens",
"gleps",
"glera",
"gleri",
"glers",
"glett",
"glíða",
"gliði",
"gliðs",
"gliðu",
"glima",
"glíma",
"glimt",
"glímt",
"glímu",
"glint",
"glisa",
"glísa",
"glísu",
"glóða",
"gløða",
"gløgg",
"gløgs",
"gløgt",
"glógv",
"gløir",
"glóma",
"glómu",
"glopa",
"glopp",
"glops",
"glóri",
"glórs",
"glosa",
"glósa",
"gløst",
"glosu",
"glósu",
"glots",
"glott",
"gløtt",
"gloym",
"gloyp",
"glugg",
"glugs",
"glumt",
"glunt",
"glúpa",
"glúpi",
"glups",
"glúps",
"glýsa",
"glýsu",
"gnadd",
"gnads",
"gnaga",
"gnegs",
"gneis",
"gnelt",
"gnisa",
"gnisi",
"gníst",
"gnýið",
"gnýin",
"gnýur",
"goðan",
"góðan",
"goðar",
"góðar",
"gøddi",
"gøddu",
"góðga",
"goðin",
"góðin",
"góðir",
"gøðir",
"góðsa",
"góðsi",
"goðum",
"góðum",
"góður",
"goffa",
"goffi",
"gogga",
"goggi",
"goggu",
"gólað",
"gólan",
"gølan",
"gólar",
"golfa",
"golfs",
"gólið",
"gølli",
"gølti",
"gólum",
"gølur",
"gólva",
"gólvi",
"gólvs",
"góman",
"gómar",
"gómin",
"gomlu",
"gomul",
"gómum",
"gómur",
"gonga",
"gongd",
"gongi",
"gongt",
"gongu",
"gonna",
"gønum",
"gorað",
"goran",
"góran",
"gorar",
"górar",
"gorið",
"górin",
"gorpa",
"gorpi",
"gorps",
"gorra",
"gorri",
"gørti",
"górum",
"gorut",
"górut",
"gosið",
"gosin",
"gosna",
"gøsól",
"gosop",
"gøsst",
"gótað",
"gotan",
"gøtan",
"gotar",
"gótar",
"gotið",
"gotin",
"gotra",
"gotri",
"gotta",
"gotum",
"gøtum",
"gotur",
"gøtur",
"gøvig",
"gøvug",
"goyan",
"goyði",
"goyðu",
"goyma",
"goymd",
"goymi",
"goymt",
"goysa",
"goyst",
"goyum",
"goyur",
"gráan",
"gráar",
"graða",
"gráða",
"graði",
"gráði",
"græla",
"gráin",
"gráir",
"graml",
"gramm",
"grams",
"gramt",
"grana",
"grána",
"grand",
"grann",
"grasa",
"grasi",
"gráta",
"gráti",
"gráts",
"gratt",
"grátt",
"gráum",
"gráur",
"grava",
"greði",
"greið",
"grein",
"greip",
"grell",
"grels",
"gremi",
"gremj",
"grenj",
"grepp",
"greps",
"Greta",
"grets",
"grett",
"Gretu",
"greyt",
"griði",
"grift",
"grill",
"grima",
"gríma",
"grimd",
"grími",
"Grími",
"grimm",
"Gríms",
"grimt",
"grimu",
"grímu",
"grína",
"grind",
"grini",
"grins",
"grinu",
"gripa",
"grípa",
"gripi",
"grips",
"gripu",
"grísa",
"grísi",
"grísk",
"grísl",
"grøða",
"grødd",
"gróði",
"grøði",
"gróðu",
"grógv",
"gróma",
"gromm",
"grómu",
"grona",
"gróna",
"grøna",
"gróni",
"grøni",
"grønt",
"grønu",
"grópa",
"grópi",
"gróps",
"grópu",
"grøta",
"gróti",
"gróts",
"grøtt",
"grótu",
"grova",
"gróva",
"grøva",
"grovt",
"grovu",
"gróvu",
"groys",
"grugg",
"grugs",
"grúka",
"grúki",
"grúks",
"grumm",
"grumt",
"gruna",
"grund",
"gruni",
"grunn",
"gruns",
"grunt",
"grupa",
"grúpa",
"grúsa",
"grúsi",
"grutt",
"grúva",
"grúvu",
"grýla",
"grýlu",
"grýna",
"grýni",
"grýns",
"grynt",
"grýta",
"grýtt",
"grýtu",
"gubba",
"gubbi",
"gudað",
"gudar",
"gudin",
"Gudný",
"gudum",
"gudur",
"guide",
"gulað",
"Gulak",
"gulan",
"gular",
"guldu",
"gulið",
"gulir",
"gulli",
"gulna",
"gulum",
"gulur",
"gulut",
"gumma",
"gummi",
"gummu",
"gumpa",
"gumpi",
"gumps",
"Gundi",
"gúppa",
"gúppi",
"gurka",
"gurku",
"Gurli",
"Gutta",
"Gutti",
"guvað",
"guvan",
"guvar",
"guvin",
"guvir",
"guvum",
"guvur",
"gvagg",
"gvags",
"gyðja",
"gyðju",
"gygdi",
"gygdu",
"gykla",
"gykli",
"gykls",
"gylla",
"gylta",
"gylti",
"gyltu",
"Gylva",
"Gylvi",
"gyrða",
"gyrdi",
"gyrði",
"gyrdu",
"gyrnu",
"gyski",
"gýsur",
"gýtir",
"gýtti",
"gýttu",
"gývil",
"gývli",
"hábær",
"háðað",
"hádag",
"haðan",
"háðan",
"háðar",
"háðin",
"háðló",
"hædda",
"hægra",
"hægri",
"hægru",
"hægst",
"hækka",
"hækki",
"hælað",
"hælar",
"hælin",
"hælir",
"hælum",
"hælur",
"hærda",
"hærut",
"hæsað",
"hæsar",
"hæsið",
"hæsis",
"hæsti",
"hæstu",
"hætta",
"hætti",
"hættu",
"hafta",
"hafti",
"hafts",
"hagað",
"hagan",
"hagar",
"hagga",
"hagin",
"hagir",
"hagla",
"hagli",
"hagls",
"hagum",
"hagur",
"háhús",
"haiað",
"haiar",
"Haiti",
"hakan",
"hakar",
"hakin",
"hakka",
"hakki",
"hákøn",
"hakum",
"Hákun",
"hálað",
"halan",
"hálan",
"halar",
"hálar",
"halda",
"hálda",
"haldi",
"halds",
"háldu",
"halga",
"halgi",
"halgu",
"hálið",
"hálig",
"halin",
"hálir",
"hálka",
"hálku",
"halla",
"halli",
"halló",
"hálmi",
"hálms",
"hálop",
"hálov",
"hálsa",
"hálsi",
"halta",
"halti",
"halum",
"hálum",
"hálur",
"hálva",
"hálvd",
"hálvi",
"hálvt",
"hálvu",
"hamað",
"háman",
"hamar",
"hámar",
"hamin",
"hamir",
"hámir",
"hamla",
"hampa",
"hampi",
"hamps",
"hamra",
"hamri",
"hamsa",
"hamsi",
"hamum",
"hámum",
"hamur",
"hámur",
"hanan",
"hanar",
"handa",
"handi",
"hanga",
"hangi",
"hanin",
"hanna",
"hanni",
"Hanoi",
"Hansi",
"hanum",
"Hanus",
"happa",
"happi",
"hárað",
"haran",
"hárar",
"harav",
"harða",
"harði",
"hárið",
"Hárik",
"háriv",
"harka",
"harki",
"harks",
"harma",
"harmi",
"harms",
"harmt",
"háróm",
"háróp",
"harpa",
"harpu",
"harra",
"harri",
"harta",
"harum",
"hárum",
"harur",
"hárut",
"harva",
"harvu",
"hasað",
"hásal",
"hasan",
"hásan",
"hasar",
"hásar",
"hásig",
"hasin",
"hasir",
"hásir",
"haska",
"haski",
"hassj",
"hasta",
"hasti",
"hasts",
"hasum",
"hásum",
"hásur",
"hatað",
"hatar",
"hatið",
"hátíð",
"hatri",
"hatsk",
"hatta",
"hátta",
"hatti",
"hátti",
"hátún",
"hatur",
"hávað",
"havál",
"havan",
"hávan",
"havar",
"hávar",
"Hávar",
"havdu",
"havið",
"havin",
"hávin",
"havís",
"havna",
"havra",
"havri",
"havst",
"havum",
"hávum",
"havur",
"hávur",
"Heðin",
"hefta",
"hefti",
"heftu",
"hegla",
"hegli",
"hegls",
"hegna",
"hegni",
"hegns",
"hegnt",
"hegra",
"hegri",
"heiað",
"heiar",
"heiða",
"Heidi",
"heiði",
"heila",
"heild",
"heili",
"heilt",
"heilu",
"heima",
"heimi",
"Heimi",
"heims",
"Heina",
"Heini",
"heita",
"heiti",
"heits",
"heitt",
"heitu",
"hekil",
"hekka",
"hekku",
"hekla",
"hekli",
"heksa",
"hekta",
"hekti",
"helda",
"heldi",
"heldu",
"Helga",
"Helgi",
"Helgu",
"helja",
"helju",
"hella",
"Hella",
"helli",
"hellu",
"helma",
"helró",
"helsi",
"helst",
"helti",
"heltn",
"heltu",
"helvt",
"hemar",
"hemri",
"henda",
"hendi",
"hendu",
"henni",
"henta",
"henti",
"hentu",
"hepna",
"hepni",
"hepnu",
"heppa",
"heppi",
"herar",
"herða",
"herdi",
"herdu",
"herðu",
"herin",
"herir",
"herja",
"herji",
"herkt",
"herma",
"hermt",
"herna",
"hersa",
"hersi",
"herum",
"herur",
"hesar",
"hesin",
"hesir",
"hesli",
"hespa",
"hespu",
"hesta",
"hesti",
"Hesti",
"hests",
"Hests",
"hesum",
"hetja",
"hetju",
"hetta",
"hettu",
"hevda",
"hevði",
"hevja",
"hevji",
"hevna",
"hevnd",
"hevnt",
"hevst",
"hevur",
"heyað",
"heyar",
"heyga",
"heygi",
"heygs",
"heyka",
"heyki",
"heyks",
"heysa",
"heysi",
"heyst",
"hiðan",
"híðið",
"híðum",
"higar",
"higst",
"hikki",
"hildu",
"hilla",
"hímað",
"hímar",
"hímið",
"himin",
"himla",
"himli",
"himna",
"himni",
"himpr",
"hímut",
"hinar",
"hinda",
"hindi",
"hindu",
"hingu",
"hinir",
"hinka",
"hinna",
"hinnu",
"hinsa",
"hinsi",
"hinum",
"hippa",
"hippi",
"hirða",
"hirdi",
"hirði",
"hirdu",
"hirja",
"hirju",
"hirsa",
"hirsi",
"hísst",
"hista",
"histi",
"hitað",
"hitan",
"hitar",
"hítar",
"hitið",
"hitin",
"hítin",
"hítir",
"hitna",
"hitst",
"hitta",
"hitti",
"hittu",
"hítum",
"hivað",
"hivar",
"hivið",
"hivna",
"hivum",
"hjall",
"hjálm",
"hjálp",
"hjals",
"hjalt",
"hjána",
"hjara",
"hjarr",
"hjars",
"hjóla",
"hjóli",
"hjóls",
"hjølt",
"hjóma",
"hjómi",
"hjóms",
"hjørt",
"hjúla",
"hjúli",
"hjúls",
"hjúna",
"hjúni",
"hjúns",
"hjúpa",
"hjúpu",
"hóast",
"hóbit",
"hødda",
"høddu",
"høgan",
"høgar",
"høgdi",
"høgdu",
"høgga",
"høggi",
"høgir",
"Høgna",
"Høgni",
"høgra",
"høgri",
"høgru",
"høgum",
"høgur",
"hógva",
"hógvi",
"høkan",
"hokið",
"høkil",
"hokin",
"høkja",
"høkju",
"hokka",
"høkla",
"høkli",
"hokna",
"hokni",
"høkum",
"høkur",
"holað",
"holan",
"holar",
"hólar",
"holda",
"hølda",
"holdi",
"høldi",
"holds",
"hølds",
"høldu",
"hólga",
"hólgi",
"holið",
"hólið",
"hølið",
"hólin",
"hølir",
"hølis",
"hólka",
"hólki",
"hólks",
"holla",
"hólla",
"hølla",
"holli",
"hólli",
"hølli",
"hollu",
"hólma",
"hólmi",
"hólms",
"hólpa",
"hólpi",
"hólps",
"holum",
"hólum",
"hølum",
"holur",
"hólur",
"holut",
"holva",
"hølva",
"holvd",
"holvt",
"hómað",
"hóman",
"hómar",
"hómin",
"homla",
"homlu",
"hómur",
"hønan",
"honar",
"hongd",
"hongt",
"honin",
"honir",
"honka",
"honki",
"honks",
"honum",
"hónum",
"hønum",
"hønur",
"hopað",
"hópað",
"hopar",
"hópar",
"hopið",
"høpið",
"hópin",
"høpin",
"høpis",
"hopla",
"høpna",
"hoppa",
"hoppi",
"hopra",
"hopum",
"hópum",
"hópur",
"hórað",
"horan",
"hórar",
"hørðu",
"hørga",
"hørgi",
"hørgs",
"horið",
"hørin",
"horna",
"horni",
"horns",
"hørpa",
"hørpu",
"horum",
"horur",
"hørur",
"horva",
"horvt",
"hosað",
"hosan",
"hosar",
"hósd.",
"hóska",
"hóski",
"hósks",
"høsna",
"høsni",
"høsns",
"hosta",
"hosti",
"hosum",
"hosur",
"hótið",
"hótta",
"hótti",
"høtti",
"hóttu",
"hótum",
"hóvað",
"hóvan",
"hóvar",
"høvda",
"høvdi",
"høvdu",
"hovið",
"hóvið",
"høvið",
"høvil",
"hovin",
"hóvin",
"høvir",
"høvis",
"høvla",
"høvli",
"hovna",
"hovnu",
"hóvum",
"høvum",
"hóvur",
"høvur",
"hoyið",
"hoykt",
"hoyna",
"hoyni",
"hoyra",
"hoyrd",
"hoyri",
"hoyrn",
"hoyrt",
"húðað",
"húðál",
"húðar",
"húðin",
"húðir",
"húðum",
"hugað",
"hugan",
"hugar",
"hugdi",
"hugdu",
"hugin",
"hugla",
"húgla",
"hugna",
"hugni",
"hugró",
"hugsa",
"hugsi",
"hugum",
"hugur",
"húgva",
"húgvu",
"húkar",
"húkin",
"húkum",
"húkur",
"hulda",
"Hulda",
"huldi",
"huldu",
"Huldu",
"hulið",
"hulin",
"hulna",
"human",
"humla",
"humli",
"humlu",
"humor",
"húnan",
"húnar",
"hunda",
"hundi",
"hunds",
"húnin",
"húnum",
"huppa",
"huppi",
"huran",
"hurða",
"hurla",
"hurli",
"hurls",
"hurra",
"hurrá",
"hurri",
"hurta",
"húsað",
"húsan",
"húsar",
"húsið",
"húsin",
"húski",
"husku",
"hússj",
"húsum",
"hutsa",
"hvæla",
"hvæli",
"hvæls",
"hvæsa",
"hvæsi",
"hvæst",
"hvakk",
"hvala",
"hvála",
"hvali",
"hváli",
"hvals",
"hváls",
"hválv",
"hvamm",
"hvams",
"hvapp",
"hvapt",
"hvarm",
"hvarv",
"hvast",
"hvein",
"hvept",
"hvess",
"hvest",
"hvíað",
"hvíar",
"hvíla",
"hvíld",
"hvíli",
"hvílt",
"hvílu",
"hvina",
"hvína",
"hvini",
"hvins",
"hvinu",
"hvísl",
"hvíta",
"hvíti",
"hvítt",
"hvítu",
"hvøkk",
"hvøks",
"hvøkt",
"hvøll",
"hvølp",
"hvølt",
"hvølv",
"hvonn",
"hvønn",
"hvøns",
"hvøpp",
"hvørs",
"hvørt",
"hvørv",
"hvøss",
"hygga",
"hyggi",
"hýggi",
"hýggj",
"hygna",
"hygni",
"hygnu",
"hykla",
"hykli",
"hykls",
"hýkur",
"hylik",
"hylin",
"hylja",
"hylki",
"hylkt",
"hylla",
"hylti",
"hyltu",
"hylur",
"hýnum",
"hyppa",
"hýran",
"hýrda",
"hýrin",
"hyrnd",
"hyrnt",
"hýrum",
"hýrur",
"hýsan",
"hýsir",
"hýsis",
"hýski",
"hýssj",
"hýsti",
"hýstu",
"hýsum",
"hýsur",
"íbirt",
"íbláa",
"íblái",
"íbúða",
"íbygd",
"íbygt",
"ideal",
"íðkað",
"íðkan",
"íðkar",
"íðkið",
"ídnað",
"ídnan",
"ídnar",
"ídnið",
"ídnir",
"ídnis",
"ídnum",
"iðrað",
"iðran",
"iðrar",
"íðuna",
"íðuni",
"íelvi",
"íferð",
"ígang",
"ígðan",
"ígðum",
"ígður",
"ígekk",
"ígera",
"ígerð",
"igils",
"iglan",
"iglar",
"iglin",
"iglum",
"iglut",
"ígrøn",
"ígula",
"ígult",
"íhald",
"íhavi",
"íhavs",
"íkast",
"íkoma",
"íkomu",
"ílætt",
"ílaga",
"ílagi",
"ílags",
"ílagt",
"ílata",
"íláta",
"ílati",
"íláti",
"ílats",
"íláts",
"ílega",
"ílegg",
"ílegs",
"ílegu",
"íleik",
"ílend",
"ílent",
"ílíki",
"ilina",
"ilini",
"iljar",
"iljum",
"illað",
"illan",
"illar",
"illir",
"illum",
"illur",
"ilnar",
"íløga",
"íløgd",
"íløgu",
"íløtt",
"ilska",
"ilskt",
"ilsku",
"iltið",
"iltin",
"ímaði",
"ímaðu",
"ímast",
"ímgrá",
"ímint",
"immun",
"ímóti",
"Ímund",
"ímuta",
"ímynd",
"India",
"Indiu",
"indra",
"indri",
"indur",
"innan",
"innar",
"innat",
"innbý",
"innií",
"innin",
"innir",
"innum",
"insta",
"instu",
"intur",
"inuit",
"ípota",
"ípoti",
"ípots",
"iraki",
"Iraks",
"irana",
"irani",
"Irans",
"írans",
"Irena",
"Irenu",
"íreyð",
"íriva",
"írivi",
"írivs",
"ironi",
"írska",
"írski",
"írskt",
"írsku",
"ísáað",
"ísáar",
"ísaða",
"ísádd",
"isaði",
"ísaði",
"ísáði",
"ísáðs",
"isaðu",
"ísaðu",
"Ísaki",
"Ísaks",
"ísara",
"ísára",
"ísari",
"ísári",
"ísárs",
"ísast",
"ísátt",
"ísenn",
"ísett",
"ísfon",
"ísfrí",
"íshús",
"ísing",
"islam",
"íslit",
"Ísmal",
"ismum",
"ismur",
"ísnað",
"ísnám",
"ísnar",
"ísoga",
"ísogu",
"ísøld",
"ísopp",
"ísops",
"ístíð",
"ístig",
"ístra",
"ístri",
"ístur",
"ítaka",
"ítaki",
"ítaks",
"ítast",
"ítøka",
"ítøkt",
"ítøku",
"ítriv",
"ivaáa",
"ivaær",
"ivans",
"ivaóm",
"Ívari",
"Ívars",
"ivast",
"ívera",
"íveri",
"íveru",
"iving",
"ivist",
"ívist",
"ivrig",
"jáaði",
"jáaðu",
"jabba",
"jabbi",
"jadan",
"jaðar",
"jaðra",
"jaðri",
"jaður",
"Jafet",
"jagga",
"jaggi",
"jagla",
"jagta",
"jakka",
"jakki",
"jaksl",
"jakta",
"Jákup",
"jalig",
"jalla",
"jalli",
"jalov",
"jamba",
"jambu",
"jamra",
"janga",
"jánka",
"Janus",
"jaorð",
"Japan",
"japsa",
"jarða",
"jarði",
"jarma",
"jarmi",
"jarms",
"jarni",
"jarpa",
"jarpt",
"jarva",
"jarvi",
"jarvs",
"Jason",
"jassa",
"jassi",
"játan",
"játta",
"játti",
"javna",
"javni",
"javnt",
"Jemen",
"Jenis",
"Jenný",
"Jensi",
"jeppa",
"jeppi",
"jesað",
"jesan",
"jesar",
"Jesar",
"jesus",
"Jóani",
"jóans",
"Jóans",
"jødan",
"jødar",
"jodið",
"jødin",
"jodla",
"jødum",
"Jóeli",
"Jóels",
"jogga",
"Jóhan",
"Johni",
"Johns",
"jøkil",
"jøkla",
"jøkli",
"jøkul",
"jólar",
"jólin",
"jolla",
"jollu",
"jólum",
"jólur",
"jompa",
"jonað",
"jonar",
"Jónar",
"Jónas",
"jonin",
"jonir",
"Jonni",
"jonum",
"Jónvá",
"Jósef",
"Jósup",
"Josva",
"jótra",
"jótri",
"jøtun",
"jótur",
"joule",
"jøvnu",
"Júdeu",
"Judit",
"júgra",
"júgri",
"júgur",
"júkað",
"júkan",
"júkar",
"júkið",
"júkut",
"Julia",
"Júlia",
"Juliu",
"Júliu",
"jumbo",
"júmen",
"junta",
"juntu",
"jústa",
"Jústa",
"Jústu",
"jútan",
"jútar",
"jútin",
"jútsk",
"Jutta",
"Jútta",
"Juttu",
"Júttu",
"jútum",
"kabal",
"kabul",
"kaðal",
"kaðla",
"kaðli",
"kæran",
"kærar",
"kærdi",
"kærdu",
"kærir",
"kærum",
"kærur",
"kæsir",
"kæsti",
"kæstu",
"kætið",
"kætin",
"kætis",
"kaffi",
"kagað",
"kagar",
"kagga",
"kaggi",
"kagið",
"kagin",
"kaiar",
"kaiin",
"kaiir",
"kaium",
"kákað",
"kakan",
"kákan",
"kakao",
"kákar",
"kákið",
"kákli",
"kákls",
"kakum",
"kákum",
"kakur",
"kálað",
"kálar",
"kalda",
"kaldi",
"kaldu",
"kalið",
"kálið",
"kalik",
"kalin",
"kalir",
"kalis",
"kálka",
"kálki",
"kálks",
"kalla",
"kalli",
"Kalsø",
"kálum",
"kalur",
"kalva",
"kálva",
"kalvi",
"kálvi",
"Kálvi",
"Kálvs",
"kámað",
"káman",
"kamar",
"kámar",
"kamba",
"kambi",
"kambs",
"kamel",
"kamin",
"kámir",
"kampa",
"kampi",
"kamps",
"kamsi",
"kámti",
"kámum",
"kámur",
"kámut",
"kanal",
"kanel",
"kanin",
"kanna",
"kanni",
"kannu",
"kanoa",
"kanón",
"kanst",
"kanta",
"kanti",
"kants",
"kánus",
"kápan",
"kápil",
"kápla",
"kápli",
"kappa",
"kappi",
"kápum",
"kápur",
"kárað",
"karða",
"karði",
"kargt",
"karið",
"Karin",
"Karla",
"Karli",
"Karls",
"Karlu",
"karma",
"karmi",
"karms",
"karpa",
"karpu",
"karra",
"karrá",
"karri",
"karry",
"karsa",
"karsi",
"karti",
"karva",
"karvi",
"kasað",
"kasar",
"kassa",
"kássa",
"kassi",
"kássu",
"kasta",
"kasti",
"kasts",
"kátan",
"Katar",
"kátar",
"kátir",
"katta",
"katti",
"kátum",
"kátur",
"kavað",
"kávað",
"kavan",
"kavar",
"kávar",
"kávið",
"kavin",
"kavrá",
"kavum",
"kávum",
"kavut",
"kedda",
"keddi",
"keddu",
"keðir",
"keður",
"kegla",
"kegli",
"kegls",
"keiar",
"keiin",
"keiir",
"keika",
"keiki",
"keiks",
"keikt",
"keila",
"keili",
"keils",
"keipa",
"keipi",
"keips",
"keipu",
"keium",
"kekan",
"kekar",
"kekin",
"kekka",
"kekki",
"keksa",
"keksi",
"keksk",
"keksu",
"kekum",
"kekur",
"kelað",
"kelan",
"kelar",
"kelda",
"keldu",
"kelið",
"kelin",
"kelka",
"kelki",
"kelks",
"kelkt",
"kelna",
"kelta",
"kelti",
"kelva",
"kelvi",
"kelvs",
"kemba",
"kembd",
"kembt",
"kempa",
"kempu",
"kemst",
"kemur",
"kenda",
"kendi",
"kendu",
"Kenja",
"kenji",
"kenna",
"kenni",
"kensl",
"kenst",
"keppa",
"keppi",
"kerið",
"kerja",
"kerna",
"kerra",
"kerru",
"kerta",
"kerti",
"kertu",
"kerub",
"kerúb",
"kerum",
"kerva",
"kervi",
"ketan",
"ketil",
"Ketil",
"ketla",
"ketli",
"Ketli",
"ketta",
"kettu",
"ketum",
"ketur",
"kevan",
"kevar",
"kevin",
"kevum",
"keyla",
"keylu",
"keymi",
"keyms",
"keypa",
"keypi",
"keyps",
"keypt",
"keysa",
"khaki",
"kikað",
"kíkað",
"kikar",
"kíkar",
"kíkin",
"kikka",
"kikni",
"kiksa",
"kiksi",
"kíkum",
"kíkur",
"kílað",
"kílan",
"kílar",
"kílin",
"kiloa",
"kiloi",
"kilos",
"kilpa",
"kilpi",
"kilps",
"kílum",
"kimað",
"kiman",
"kimar",
"kínan",
"kinda",
"kindi",
"kíndi",
"kinds",
"kíndu",
"kinga",
"Kingo",
"kínið",
"kinin",
"kínir",
"kinka",
"kinki",
"kinks",
"kinna",
"kínum",
"kiosk",
"kippa",
"kippi",
"kippu",
"kipsa",
"kipsi",
"kipti",
"kiptu",
"kirna",
"kirnu",
"kirri",
"kirva",
"kirvi",
"kisað",
"kisan",
"kisar",
"kisil",
"kiska",
"kisku",
"kisli",
"kista",
"kísta",
"kísti",
"kistu",
"kisum",
"kisur",
"kitla",
"kitli",
"kitls",
"kitta",
"kitti",
"Kjadd",
"kjaft",
"kjaka",
"kjaki",
"kjaks",
"kjala",
"kjánk",
"kjans",
"kjarr",
"kjars",
"kjóan",
"kjóar",
"kjóin",
"kjøkr",
"kjóla",
"kjøla",
"kjóli",
"kjøli",
"kjøra",
"kjósa",
"kjósi",
"kjøti",
"kjøts",
"kjóum",
"kjúka",
"kjúku",
"kláað",
"kláar",
"kláða",
"kláði",
"klæða",
"klædd",
"klæði",
"klæna",
"klæni",
"klænt",
"klænu",
"klætt",
"klaff",
"klafs",
"klaga",
"klagu",
"klaka",
"klaki",
"klakk",
"klaks",
"klakt",
"klamp",
"klams",
"klang",
"klapp",
"klaps",
"klára",
"klári",
"klárs",
"klárt",
"kláru",
"klasa",
"klasi",
"Kláus",
"klava",
"klavi",
"klavu",
"kleiv",
"klekt",
"klemm",
"klems",
"klepp",
"kleps",
"klept",
"klerk",
"klets",
"klett",
"kleyv",
"klika",
"klikk",
"kliks",
"klikt",
"klína",
"klínd",
"kling",
"klink",
"klínt",
"klípa",
"klípi",
"klipp",
"klips",
"klíps",
"klipt",
"klípt",
"klípu",
"klirr",
"kliva",
"klíva",
"klivi",
"klívi",
"klivs",
"klivu",
"klóar",
"klødd",
"klógv",
"klóir",
"klóka",
"klóki",
"kløkk",
"klókt",
"kløkt",
"klóku",
"klong",
"klønu",
"kløpp",
"klóra",
"klori",
"klóri",
"klors",
"klórs",
"kloss",
"klóta",
"klótu",
"kløur",
"klova",
"klovi",
"klovs",
"kluft",
"klukk",
"klump",
"klums",
"klunk",
"klúss",
"klúta",
"klúti",
"klúts",
"kluvu",
"klykt",
"knáan",
"knáar",
"knæið",
"knaga",
"knagi",
"knáir",
"knaka",
"knaki",
"knaks",
"knapp",
"knaps",
"knapt",
"knara",
"knark",
"knarr",
"knars",
"knasa",
"knasi",
"knask",
"knast",
"knátt",
"knáum",
"knáur",
"knáva",
"knavi",
"knávt",
"knekk",
"kneks",
"knekt",
"knepa",
"knepi",
"kneps",
"knept",
"kneyk",
"kneys",
"knikk",
"kniks",
"knípa",
"knipr",
"knípt",
"knípu",
"knisa",
"knisi",
"kníva",
"knívi",
"knívs",
"knoða",
"knokk",
"knoks",
"knópa",
"knópi",
"knøpp",
"knóps",
"knóra",
"knóri",
"knøri",
"knørr",
"knørt",
"knosa",
"knoss",
"knota",
"knøtt",
"knotu",
"knøum",
"knubb",
"knubs",
"knugg",
"knugs",
"knúka",
"knúki",
"knúks",
"knurr",
"knurs",
"knúsa",
"knúsi",
"knúst",
"knúta",
"knúti",
"Knúti",
"knúts",
"Knúts",
"knútu",
"knykk",
"knyks",
"knykt",
"knyll",
"knyls",
"knylt",
"knýta",
"knýti",
"knýtt",
"kobba",
"kobbi",
"kobla",
"kodan",
"kodda",
"koddi",
"kódna",
"kodum",
"kodur",
"kógva",
"kógvi",
"kóini",
"kokað",
"kókað",
"kokan",
"kókan",
"køkan",
"kokar",
"kókar",
"køkar",
"kókið",
"køkin",
"køkja",
"køkju",
"kokka",
"kokki",
"kokra",
"koksa",
"koksi",
"koksu",
"kokti",
"køkti",
"koktu",
"kokum",
"kókum",
"køkum",
"kokur",
"køkur",
"kokus",
"kolað",
"kólað",
"kolan",
"kólan",
"kolar",
"kólar",
"kolba",
"kolbu",
"kølda",
"køldi",
"køldu",
"kolið",
"kólið",
"kølið",
"kølin",
"kølir",
"kolla",
"kolli",
"kólna",
"kolon",
"kolum",
"kolur",
"kolut",
"koman",
"komdi",
"komdu",
"komet",
"komið",
"komin",
"komir",
"komma",
"kommu",
"komna",
"komni",
"komnu",
"komst",
"komum",
"komur",
"kømur",
"konan",
"kønan",
"kønar",
"kondi",
"konga",
"kongi",
"Kongo",
"kongs",
"kønið",
"kønir",
"kønis",
"konka",
"kønka",
"konku",
"konta",
"konti",
"konto",
"kontu",
"konum",
"kónum",
"kønum",
"konur",
"kønur",
"kopar",
"kópar",
"kopek",
"kópin",
"kopla",
"koppa",
"koppi",
"kopra",
"køpsa",
"køpsi",
"kópum",
"kópur",
"korál",
"koran",
"korða",
"korði",
"korðu",
"Korea",
"kørga",
"kørgi",
"kørgs",
"kørgu",
"kórið",
"korka",
"korki",
"korna",
"korni",
"korns",
"korta",
"korti",
"korts",
"korum",
"kórum",
"kørum",
"kósar",
"kosið",
"kosin",
"kósin",
"kósir",
"køska",
"køsku",
"kosna",
"kosni",
"kosnu",
"kossa",
"kossi",
"kosta",
"køsta",
"kosti",
"køsti",
"køsts",
"kósum",
"kotan",
"kotið",
"kotur",
"kovan",
"kovar",
"køvdi",
"køvdu",
"kovin",
"køvir",
"kóvna",
"kovum",
"koykl",
"koyla",
"koylu",
"koyna",
"koynu",
"koyra",
"koyrd",
"koyri",
"koyrs",
"koyrt",
"koyta",
"koytu",
"kráan",
"kráar",
"kradd",
"krads",
"kraft",
"kraga",
"kragi",
"kráið",
"kráir",
"kraka",
"kráka",
"kraki",
"krakk",
"kraks",
"kráku",
"kráma",
"krámi",
"kramm",
"krams",
"kráms",
"krana",
"krána",
"krani",
"krans",
"krapa",
"krapi",
"krapt",
"krása",
"krasi",
"kráum",
"krava",
"kravd",
"kravi",
"kravs",
"kravt",
"kreft",
"krema",
"kremi",
"kreml",
"krems",
"krept",
"kreta",
"kreyp",
"kriað",
"krian",
"kriar",
"krígs",
"kriið",
"krika",
"kriki",
"krill",
"kríll",
"krils",
"kríls",
"krimi",
"krími",
"kríms",
"kring",
"krinn",
"krint",
"Krist",
"krita",
"kriti",
"kritl",
"krits",
"kritu",
"króað",
"króar",
"krógv",
"kroka",
"króka",
"kroki",
"króki",
"krøki",
"krokk",
"kroks",
"krøkt",
"kroma",
"kromi",
"kroms",
"króna",
"krónu",
"kropa",
"kropi",
"kropp",
"krøpp",
"krops",
"kross",
"krota",
"kroti",
"krots",
"krova",
"krøvd",
"krovi",
"krovs",
"krúll",
"krúls",
"krumm",
"krúna",
"krúnu",
"krúpa",
"krupu",
"krúss",
"krúti",
"krutl",
"kruts",
"krúts",
"krutt",
"kruvd",
"kruvt",
"krydd",
"kryds",
"krykt",
"krýkt",
"krýna",
"krýnd",
"krýnt",
"kryva",
"kryvi",
"kryvl",
"kryvs",
"kubba",
"kubbi",
"kúgað",
"kúgan",
"kúgar",
"kugga",
"kuggi",
"kúgið",
"kúgva",
"kukka",
"kukki",
"kúlað",
"kúlan",
"kular",
"kúlar",
"kulda",
"kuldi",
"kulka",
"kulki",
"kulks",
"kulla",
"kúlla",
"kúlli",
"kullu",
"kulsa",
"kúlum",
"kúlur",
"kúlut",
"kúlva",
"kúlvi",
"kúlvs",
"kumla",
"kumli",
"kumma",
"kummu",
"kunda",
"kundi",
"kundu",
"kunna",
"kúnna",
"kunni",
"kúnni",
"kunnu",
"Kunoy",
"kunst",
"kunta",
"kuntu",
"kúnum",
"kúpan",
"kupla",
"kupli",
"kupul",
"kúpum",
"kúpur",
"kúput",
"kúran",
"kúrar",
"kurda",
"kurdi",
"kúrdi",
"kúrdu",
"kúrið",
"kuril",
"kúrin",
"kúrir",
"kurla",
"kurli",
"kurls",
"kurlu",
"kurra",
"kúrra",
"kurri",
"kursa",
"kursi",
"kurta",
"kúrum",
"kúrur",
"kurva",
"kusan",
"kúsan",
"kussa",
"kussi",
"kussu",
"kusta",
"kústa",
"kusti",
"kusts",
"kusum",
"kúsum",
"kusur",
"kúsur",
"kutað",
"kútal",
"kutan",
"kutar",
"kutið",
"kútøð",
"kútøl",
"kváða",
"kváðu",
"kvæða",
"kvæði",
"kvæma",
"kvæmt",
"kvæmu",
"kvætt",
"kvagg",
"kvags",
"kvaki",
"kvakk",
"kvaks",
"kvala",
"kvald",
"kvali",
"kválm",
"kvalt",
"kvams",
"kvart",
"kvátt",
"kveik",
"kveit",
"kvets",
"kvett",
"kveyl",
"kviða",
"kvíða",
"kviði",
"kvíði",
"kviðs",
"kvíðu",
"kvíga",
"kvígu",
"kvika",
"kviki",
"kvikt",
"kviku",
"kvink",
"kvirr",
"kvirt",
"kvisi",
"kvisk",
"kvísl",
"kvist",
"kvítt",
"kvøða",
"kvøði",
"kvóðu",
"kvøðu",
"kvøld",
"kvørn",
"kvota",
"kvøtt",
"kvotu",
"kykað",
"kykan",
"kykar",
"kykin",
"kykir",
"kykna",
"kykni",
"kykns",
"kyknu",
"kyksa",
"kyksi",
"kykta",
"kykti",
"kykts",
"kykum",
"kykur",
"kyldi",
"kyldu",
"kýlið",
"kylir",
"kylja",
"kylla",
"kylti",
"kyltu",
"kýlum",
"kylva",
"kylvu",
"kymri",
"Kymru",
"kynda",
"kyndi",
"kyndu",
"kynið",
"kynja",
"kynum",
"kýpið",
"kýpin",
"kýpna",
"kyrja",
"kyrkt",
"kyrpt",
"kyrra",
"Kyrra",
"kyrri",
"Kyrri",
"kyrru",
"kýsan",
"kýsar",
"kysið",
"kýsin",
"kýska",
"kýskt",
"kyssa",
"kyssi",
"kysti",
"kystu",
"kýsum",
"kýtir",
"kýtti",
"kýttu",
"labba",
"labbi",
"laðað",
"laðar",
"laðið",
"laðin",
"ladna",
"læaði",
"læaðu",
"lægað",
"lægar",
"lægdi",
"lægdu",
"lægir",
"lægra",
"lægri",
"lægru",
"lægst",
"lækja",
"lækju",
"lækka",
"lækki",
"lækna",
"lækni",
"lælig",
"lænir",
"lænti",
"læntu",
"lænum",
"læran",
"lærár",
"lærda",
"lærdi",
"lærdu",
"lærið",
"lærir",
"lærum",
"lærur",
"læsið",
"læsir",
"læsta",
"læsti",
"læstu",
"lætið",
"lætna",
"lætst",
"lætta",
"lætti",
"lættu",
"lagað",
"lagan",
"lágan",
"lagar",
"lágar",
"lagda",
"lagdi",
"lagds",
"lagga",
"lagið",
"lágin",
"lágir",
"lagna",
"lágna",
"lágni",
"lagnu",
"lagra",
"lagst",
"lágum",
"lágur",
"Laila",
"Lailu",
"láina",
"lakað",
"lakan",
"lakar",
"lakið",
"lakin",
"lakir",
"lakka",
"lakki",
"laksa",
"laksi",
"lakum",
"lakur",
"lalla",
"lálla",
"lalli",
"lállu",
"lámað",
"laman",
"lámar",
"lamba",
"Lamba",
"lambi",
"Lambi",
"lambs",
"lamdi",
"lamdu",
"lamið",
"lamin",
"lamma",
"lamna",
"lamni",
"lamnu",
"lampa",
"lampu",
"lamsa",
"lamum",
"lámum",
"lamur",
"lámur",
"landa",
"landi",
"lands",
"langa",
"langi",
"langs",
"langt",
"lánið",
"Lanka",
"lansa",
"lansi",
"lansu",
"lánta",
"lánti",
"lántu",
"lánum",
"lapis",
"lappa",
"lappi",
"láran",
"lárar",
"lárin",
"lárir",
"larma",
"larmi",
"larms",
"larra",
"lárum",
"lárur",
"larva",
"larvu",
"laser",
"lásið",
"laska",
"laski",
"lassa",
"Lassa",
"lassi",
"Lassi",
"lasso",
"lasta",
"lasti",
"lásum",
"latan",
"látan",
"latar",
"latið",
"látið",
"latin",
"latín",
"látin",
"latir",
"latna",
"látra",
"látri",
"látst",
"latum",
"látum",
"latur",
"látur",
"Laura",
"Lauru",
"lavað",
"lavan",
"lavar",
"lavdi",
"lavdu",
"lavir",
"leasa",
"leðra",
"leðri",
"leður",
"lefsa",
"legan",
"legat",
"legði",
"legga",
"leggi",
"legna",
"legsa",
"legst",
"legum",
"legur",
"leiða",
"leidd",
"leiði",
"leiga",
"leigu",
"leika",
"leiki",
"leikt",
"leiku",
"leina",
"leini",
"leins",
"leiri",
"leirs",
"leist",
"leita",
"leith",
"leiti",
"leits",
"leitt",
"leiva",
"leivd",
"leivi",
"Leivi",
"leivs",
"Leivs",
"leivt",
"lekan",
"lekar",
"lekin",
"lekir",
"lekja",
"lekta",
"lekti",
"lektu",
"lekum",
"lekur",
"lella",
"lellu",
"lemba",
"lembt",
"lemja",
"lemma",
"lemmi",
"lempa",
"lemur",
"lenda",
"lendi",
"lendu",
"lenið",
"lensa",
"lensu",
"lenum",
"Leona",
"Leoni",
"Leons",
"lepan",
"lepar",
"lepin",
"leppa",
"lepra",
"lepti",
"leptu",
"lepum",
"leput",
"lerir",
"lerka",
"lerki",
"lerks",
"lesið",
"lesir",
"leska",
"leski",
"lesks",
"lespa",
"lessa",
"lessi",
"lesti",
"lestu",
"lesur",
"letað",
"letar",
"letið",
"letin",
"letst",
"letta",
"letti",
"letur",
"Levis",
"levsa",
"levsu",
"leyg.",
"leyga",
"leyka",
"leyki",
"leyks",
"leypa",
"leypi",
"leyps",
"leysa",
"leysi",
"leysn",
"leyss",
"leyst",
"leysu",
"leyva",
"leyvi",
"leyvs",
"leyvu",
"Lhasa",
"Libya",
"libyi",
"liðað",
"liðan",
"liðar",
"líðar",
"lídda",
"liðið",
"liðin",
"líðin",
"liðir",
"líðir",
"liðka",
"lidna",
"liðna",
"lidnu",
"liðug",
"liðum",
"líðum",
"liður",
"líður",
"lifra",
"lifri",
"lifrs",
"ligan",
"liggi",
"líggi",
"ligið",
"ligum",
"ligur",
"líkað",
"likam",
"líkan",
"líkar",
"likið",
"líkið",
"likin",
"líkin",
"líkir",
"líkis",
"líkja",
"likka",
"likku",
"líkna",
"likør",
"líkst",
"líkum",
"líkur",
"lilja",
"Lilja",
"lilju",
"Lilju",
"lilla",
"limað",
"límað",
"limar",
"límar",
"límið",
"limin",
"limir",
"limum",
"limur",
"linað",
"línað",
"linan",
"línan",
"linar",
"línar",
"Línar",
"linda",
"Linda",
"lindi",
"Lindu",
"linga",
"línið",
"linin",
"linir",
"linja",
"linju",
"linka",
"linki",
"linna",
"linni",
"linsa",
"linsu",
"linum",
"línum",
"linur",
"línur",
"Lions",
"lippa",
"lippu",
"liran",
"líran",
"lirar",
"lírar",
"lirin",
"lírin",
"lirka",
"lirra",
"lirum",
"lírum",
"lisið",
"lisin",
"lisna",
"lisnu",
"lissa",
"lissi",
"lisst",
"lissu",
"lista",
"listi",
"listu",
"litað",
"litar",
"lítar",
"litið",
"lítið",
"lítil",
"litin",
"lítin",
"litir",
"lítla",
"lítli",
"lítlu",
"litra",
"litri",
"lítst",
"litta",
"litti",
"littu",
"litum",
"litur",
"lítur",
"livað",
"livdi",
"lívdi",
"livdu",
"lívdu",
"lívga",
"livið",
"lívið",
"livir",
"lívir",
"livna",
"livra",
"lívsá",
"livst",
"lívum",
"livur",
"ljóða",
"ljóði",
"ljóðs",
"ljóma",
"ljómi",
"ljóms",
"ljóna",
"ljóni",
"ljóns",
"ljósa",
"ljósá",
"Ljósá",
"ljósi",
"ljóst",
"ljósu",
"ljóta",
"ljótt",
"ljótu",
"ljúva",
"ljúvt",
"lobba",
"lobby",
"loðáa",
"loðað",
"loðær",
"løðan",
"loðar",
"lodda",
"lødda",
"loddi",
"løddi",
"løddu",
"loðið",
"loðin",
"løðir",
"lodna",
"lodnu",
"loðóm",
"lóðri",
"loðsa",
"loðsi",
"loðum",
"løðum",
"lóður",
"løður",
"lofna",
"lofta",
"lofti",
"lofts",
"logað",
"løgað",
"logan",
"logar",
"lógar",
"løgar",
"løgdu",
"logga",
"løgga",
"loggi",
"løggi",
"løggu",
"logið",
"løgið",
"login",
"lógin",
"løgin",
"lógir",
"løgir",
"logna",
"løgna",
"løgni",
"løgnu",
"løgst",
"logum",
"lógum",
"løgum",
"løgur",
"lógva",
"lógvi",
"lóina",
"lóini",
"lokað",
"lókað",
"lokal",
"lokan",
"lokar",
"lókar",
"lokið",
"lókið",
"lokin",
"lókin",
"løkin",
"løkir",
"lokka",
"lokki",
"lokna",
"lokum",
"lókum",
"løkum",
"lokur",
"lókur",
"løkur",
"lókut",
"lómar",
"lómin",
"lómum",
"lómur",
"lønað",
"lonar",
"lónar",
"lønar",
"longa",
"longd",
"longi",
"longs",
"longt",
"longu",
"lónið",
"lonin",
"lónin",
"lønin",
"lonir",
"lónir",
"lønir",
"lonkt",
"lónna",
"lónni",
"lønta",
"lønti",
"løntu",
"lonum",
"lónum",
"lønum",
"lopið",
"lopin",
"lopna",
"loppa",
"loppi",
"loppu",
"lopra",
"Lopra",
"lopum",
"lorað",
"loran",
"løran",
"lorar",
"lordi",
"lorka",
"lorki",
"lorks",
"lorra",
"lorta",
"lorti",
"lorts",
"lørum",
"lørur",
"lørut",
"losáa",
"losað",
"losær",
"losar",
"losja",
"losju",
"losna",
"losóm",
"lossa",
"losta",
"løsta",
"losti",
"løsti",
"løsts",
"lotað",
"lotan",
"løtan",
"lotar",
"lotið",
"lotin",
"lotir",
"lótir",
"Lotta",
"Lottu",
"løttu",
"lotum",
"lótum",
"løtum",
"lotur",
"løtur",
"lovað",
"løvan",
"lovar",
"løvda",
"lovið",
"løvum",
"løvur",
"loyal",
"loyna",
"loynd",
"loyni",
"loynt",
"loynu",
"loypa",
"loypi",
"loypt",
"loysa",
"loysi",
"loysn",
"loyst",
"loysu",
"loyva",
"loyvd",
"loyvi",
"loyvt",
"lubba",
"lubbi",
"ludað",
"ludar",
"ludoa",
"ludoi",
"ludos",
"lúðra",
"lúðri",
"luður",
"lúður",
"lufta",
"lugar",
"lúgva",
"lúgvi",
"lúgvu",
"lúkað",
"lúkan",
"lúkar",
"Lukas",
"lukka",
"lukku",
"lúkst",
"lukta",
"lukti",
"lukts",
"lúkum",
"lúkur",
"lulla",
"lullu",
"lumma",
"lummi",
"lumpa",
"lúnað",
"lúnan",
"lúnar",
"lunda",
"lundi",
"lunga",
"lungu",
"lunka",
"lunki",
"lunna",
"lunni",
"lúnsk",
"lúnum",
"lúnur",
"lúnut",
"lupin",
"lupra",
"lúrar",
"lúrdi",
"lúrdu",
"lúrið",
"lúrin",
"lúrir",
"lurka",
"lurki",
"lurks",
"lúrna",
"lurta",
"lurti",
"lurts",
"lúrum",
"lúrur",
"lúsað",
"lúsar",
"lúsin",
"lúsir",
"lusta",
"lúsum",
"lúsut",
"lutað",
"lútað",
"lutan",
"lutar",
"lútar",
"lutin",
"lútin",
"lutir",
"lútir",
"lutta",
"lutti",
"lutum",
"lútum",
"lutur",
"lútur",
"lýdda",
"lýddi",
"lýddu",
"Lýdia",
"lýðið",
"lýðin",
"lýðir",
"Lýdiu",
"lýðka",
"lýðku",
"lýdna",
"lýdni",
"lýður",
"Lýður",
"lyfta",
"lyfti",
"lyftu",
"lýggj",
"lygna",
"lygni",
"lygnt",
"lygnu",
"lýgur",
"lykil",
"lykka",
"lýkka",
"lykki",
"lýkku",
"lykla",
"lykli",
"lykta",
"lykti",
"lyktu",
"lýkur",
"lýlig",
"lýnað",
"lýnan",
"lýnar",
"lynda",
"lyndi",
"lyngi",
"lyngs",
"lynin",
"lýnin",
"lynir",
"lynna",
"lynti",
"lyntu",
"lynum",
"lynur",
"lýran",
"lýrar",
"lýrik",
"lýrin",
"lýrum",
"lýrur",
"lýsið",
"lýsir",
"lýsis",
"lýsna",
"lysta",
"lýsta",
"lysti",
"lýsti",
"lysts",
"lystu",
"lýstu",
"lýsut",
"lýtið",
"lýtir",
"lýtis",
"lýtta",
"lýtti",
"lýttu",
"lýtum",
"lýtur",
"máaði",
"máaðu",
"máana",
"máast",
"máddi",
"maðka",
"maðki",
"maðks",
"maður",
"mæini",
"mækan",
"mækja",
"mækju",
"mældi",
"mælið",
"mælir",
"mælsk",
"mælta",
"mælti",
"mæltu",
"mænum",
"mætan",
"mætar",
"mætið",
"mætir",
"mætis",
"mætum",
"mætur",
"mafia",
"mafiu",
"magan",
"magar",
"mágar",
"Magda",
"Magdu",
"Maggu",
"magin",
"mágin",
"magna",
"Magna",
"magni",
"Magni",
"magns",
"Magnu",
"magum",
"mágum",
"mágur",
"máham",
"máhav",
"máhøv",
"maisi",
"major",
"majsi",
"makað",
"makan",
"makar",
"makið",
"makin",
"makir",
"makka",
"maksa",
"maksi",
"makta",
"makum",
"makur",
"málað",
"Malan",
"málan",
"malar",
"málar",
"málár",
"máldi",
"máldu",
"malið",
"málið",
"malin",
"malir",
"málir",
"malja",
"Malja",
"malju",
"Malju",
"malla",
"Malla",
"mallu",
"Mallu",
"málmi",
"Malmø",
"málms",
"malør",
"Malta",
"malti",
"malts",
"malum",
"málum",
"mamma",
"mammu",
"manað",
"mánað",
"mánan",
"manar",
"mánar",
"manga",
"mangt",
"mánin",
"manna",
"manni",
"mansk",
"manst",
"mánum",
"mappa",
"mappu",
"marar",
"márar",
"marða",
"marga",
"margt",
"margu",
"Maria",
"Marið",
"marin",
"Marin",
"márin",
"Marit",
"Mariu",
"Marja",
"Marju",
"marka",
"marki",
"marks",
"Marna",
"Marni",
"Marnu",
"marra",
"marri",
"marru",
"marsa",
"marsj",
"Marta",
"martl",
"Martu",
"márum",
"márur",
"marva",
"masað",
"másan",
"masar",
"másar",
"masið",
"másin",
"maska",
"maski",
"masks",
"masku",
"massa",
"massi",
"másum",
"masut",
"matað",
"mátað",
"mátan",
"matar",
"mátar",
"mátið",
"matig",
"matin",
"mátin",
"matís",
"matla",
"mátla",
"mátli",
"matna",
"mátti",
"máttu",
"mátum",
"matur",
"mávað",
"mávar",
"meðal",
"meðan",
"medda",
"meðni",
"megið",
"megin",
"megla",
"megna",
"megni",
"meiða",
"meila",
"meilu",
"meina",
"meini",
"meins",
"meint",
"meira",
"meiri",
"meisi",
"meitt",
"mekja",
"mekju",
"Mekka",
"mekla",
"mekli",
"mekls",
"mekul",
"melda",
"meldi",
"melds",
"meldu",
"mella",
"mellu",
"melón",
"melta",
"melur",
"menna",
"mensk",
"menta",
"menti",
"mentu",
"merar",
"mergi",
"meril",
"merin",
"merja",
"merki",
"merkt",
"merla",
"merli",
"Merrý",
"mersa",
"mersi",
"merum",
"mesan",
"meska",
"meski",
"mesks",
"meskt",
"messa",
"messi",
"messu",
"mesta",
"mesti",
"mestu",
"metal",
"metan",
"metár",
"metið",
"metir",
"metis",
"metra",
"metri",
"metta",
"Metta",
"metti",
"mettu",
"Mettu",
"metum",
"metur",
"meyka",
"meyla",
"meyli",
"meyls",
"meyra",
"meyru",
"Miami",
"miðað",
"miðal",
"miðar",
"miðás",
"miðbý",
"miðið",
"miðil",
"miðis",
"miðja",
"miðju",
"miðla",
"miðli",
"miðum",
"migið",
"migin",
"mígur",
"Mikal",
"mikið",
"mikil",
"mikla",
"mikli",
"miklu",
"mikro",
"mílar",
"milda",
"mildi",
"mildu",
"mílin",
"mílir",
"milta",
"milti",
"milts",
"mílum",
"minan",
"mínar",
"mínir",
"minka",
"minki",
"minna",
"minni",
"minnu",
"minør",
"minst",
"minta",
"minti",
"mints",
"mintu",
"minum",
"mínum",
"minur",
"minus",
"Mirja",
"Mirju",
"mírst",
"misgá",
"missa",
"missi",
"mista",
"misti",
"mistu",
"mitta",
"mittu",
"mjáan",
"mjáar",
"mjædd",
"mjáir",
"mjarr",
"mjars",
"mjátt",
"mjáum",
"mjáur",
"mjava",
"mjesk",
"mjøði",
"mjødn",
"mjøli",
"mjólk",
"mjøll",
"mjøls",
"mjølv",
"mjóva",
"mjúka",
"mjúki",
"mjúkt",
"mjúku",
"mobil",
"møbla",
"móðað",
"móðan",
"móðar",
"mødda",
"møddi",
"møddu",
"møðga",
"moðið",
"møðig",
"moðin",
"móðin",
"møðin",
"móðir",
"møðir",
"modna",
"møðra",
"modul",
"moðum",
"móðum",
"móður",
"møður",
"móðut",
"mogga",
"møgga",
"møggu",
"Møggu",
"mógva",
"mógvi",
"møkan",
"mókið",
"mókin",
"mókum",
"møkum",
"mókur",
"møkur",
"mókut",
"molað",
"molan",
"molar",
"molda",
"molin",
"mølin",
"mølir",
"mølma",
"mølmi",
"mølms",
"molna",
"mólst",
"mølum",
"mølur",
"molut",
"mølva",
"mølvi",
"mølvs",
"momma",
"mómót",
"mønað",
"mønan",
"mønar",
"mongd",
"mongu",
"mønin",
"mønir",
"monsk",
"mónum",
"mønum",
"mønur",
"mópot",
"moral",
"móran",
"mórar",
"morða",
"morði",
"mørði",
"morðs",
"Morið",
"mórin",
"mørin",
"mørir",
"morla",
"morli",
"morls",
"morsa",
"mórum",
"mørum",
"mørur",
"mórut",
"mosað",
"mosan",
"mosar",
"Móses",
"mosin",
"moska",
"mosku",
"møsna",
"møsni",
"møsns",
"mosum",
"mótað",
"mótan",
"mótar",
"mótið",
"møtið",
"mótig",
"mótin",
"møtir",
"møtis",
"motiv",
"møtla",
"møtli",
"motor",
"motta",
"møtta",
"møtti",
"mottu",
"møttu",
"mótug",
"mótum",
"møtum",
"moyar",
"moyra",
"moyrt",
"moyum",
"mubba",
"mubbi",
"mudda",
"muddi",
"muðum",
"muður",
"muffa",
"muffu",
"mugga",
"muggi",
"múgva",
"múgvi",
"múgvu",
"múlan",
"muldi",
"muldu",
"múlin",
"mulpa",
"multa",
"multu",
"múlut",
"munað",
"munan",
"munar",
"munda",
"mundi",
"munds",
"mundu",
"munga",
"mungi",
"mungs",
"munin",
"munir",
"múnja",
"múnju",
"munka",
"munki",
"munks",
"munna",
"munni",
"munnu",
"munti",
"muntu",
"munum",
"munur",
"múrað",
"muran",
"múrar",
"múrin",
"murkn",
"murpa",
"murpu",
"murra",
"murri",
"murru",
"murta",
"murti",
"murtl",
"murtn",
"murts",
"murum",
"múrum",
"murur",
"múrur",
"músar",
"músin",
"músli",
"mussa",
"mussi",
"músum",
"músut",
"mutað",
"mutan",
"mutar",
"mutla",
"mutli",
"mutls",
"mutra",
"mutri",
"mutur",
"muuna",
"muuni",
"mýkir",
"mykja",
"mýkja",
"mykju",
"mylda",
"mýldi",
"mýldu",
"mýlið",
"mýlir",
"mylja",
"mylkt",
"mylla",
"myllu",
"mylna",
"mylnu",
"mýlsk",
"mýlum",
"mylur",
"mynda",
"myndi",
"mynna",
"mynta",
"mynti",
"mynts",
"myntu",
"mýran",
"mýrar",
"myrda",
"myrða",
"myrdi",
"myrdu",
"mýrin",
"myrja",
"myrju",
"myrka",
"myrki",
"myrkt",
"myrku",
"myrla",
"Myrna",
"Myrnu",
"myrra",
"myrru",
"myrta",
"myrtu",
"mýrum",
"mýrur",
"mýrut",
"mysan",
"mýsut",
"mytan",
"mýtan",
"mýtum",
"mytur",
"mýtur",
"náast",
"nabba",
"nabbi",
"náðað",
"náðar",
"nadda",
"naddi",
"náddi",
"náddu",
"náðig",
"náðin",
"náðir",
"náðum",
"nældi",
"nælir",
"næman",
"næmar",
"næmir",
"næmur",
"nærka",
"nærri",
"nærru",
"nærum",
"nærur",
"næsta",
"næsti",
"næstu",
"nætur",
"nævur",
"naggi",
"nagla",
"nagli",
"naiva",
"nakað",
"nakar",
"nakið",
"nakin",
"nakka",
"nakki",
"nakna",
"nakni",
"naknu",
"nakra",
"nálar",
"nálga",
"nálgi",
"nálgs",
"nálin",
"nálir",
"nálum",
"nalva",
"nalvi",
"námað",
"náman",
"námar",
"námið",
"námir",
"namma",
"námum",
"námur",
"nanað",
"nanar",
"nanið",
"Nanna",
"Nannu",
"Nanný",
"Nansý",
"Naomi",
"nápað",
"nápar",
"nápin",
"nappa",
"nápum",
"nápur",
"narko",
"narra",
"narri",
"nartl",
"nasað",
"nasan",
"nasar",
"nasin",
"naska",
"naskt",
"naspa",
"nasum",
"nátan",
"nátar",
"natið",
"natig",
"natin",
"nátin",
"natni",
"nátta",
"nátum",
"natúr",
"nátur",
"Nauru",
"navar",
"navir",
"navna",
"navni",
"navns",
"navnu",
"navra",
"navri",
"navum",
"nebba",
"nebbi",
"neðra",
"nefsa",
"nefsi",
"neggi",
"neggj",
"negla",
"negld",
"neglt",
"neipa",
"neipu",
"neisa",
"neisu",
"neita",
"nekta",
"nelik",
"nella",
"nelli",
"nemið",
"nemir",
"nemur",
"nemus",
"Nemus",
"nenna",
"nenti",
"nentu",
"neoni",
"neons",
"Nepal",
"nepsa",
"nepsi",
"nerta",
"nerva",
"nervi",
"nesið",
"Nesið",
"nesta",
"netið",
"netja",
"netju",
"netta",
"netto",
"nettu",
"netum",
"nevað",
"nevan",
"nevar",
"nevið",
"nevin",
"nevja",
"nevna",
"nevnd",
"nevni",
"nevnt",
"nevsa",
"nevsi",
"nevum",
"neyða",
"neyra",
"neyst",
"neyta",
"neyti",
"neyts",
"neyva",
"neyvt",
"neyvu",
"niðan",
"níddi",
"níddu",
"níðið",
"níðir",
"niðra",
"niðri",
"níðsk",
"niður",
"Niels",
"nígan",
"Niger",
"nigið",
"nígið",
"nigin",
"nígur",
"nikka",
"nikki",
"nilka",
"nilki",
"nilks",
"Nilsi",
"nilta",
"ninað",
"ninar",
"Ninna",
"Ninnu",
"nípan",
"nipið",
"nipil",
"nipin",
"nípir",
"nipla",
"nipli",
"niplu",
"nipna",
"nippa",
"nipti",
"nípti",
"niptu",
"níptu",
"nípum",
"nípur",
"nísan",
"nispa",
"nispi",
"nisps",
"nispu",
"nissa",
"nissu",
"nísta",
"nísti",
"nístu",
"nísum",
"nísur",
"nitið",
"nitin",
"nitra",
"nitru",
"nítur",
"nívda",
"nívdi",
"nívdu",
"nívir",
"Njáli",
"Njáls",
"njelt",
"njóða",
"njóra",
"njósa",
"njósi",
"njósn",
"njóta",
"njóti",
"Nobel",
"noðað",
"noðar",
"noðið",
"noðna",
"nóðra",
"nóðru",
"noðum",
"noður",
"nøgda",
"nøgdu",
"nøgga",
"nøgla",
"nøglu",
"nøgst",
"nøgta",
"nógva",
"nógvi",
"nógvu",
"nokka",
"nokki",
"nokta",
"nøkta",
"nokti",
"nøkur",
"nolað",
"nolar",
"nomið",
"nomin",
"nomum",
"nónið",
"Noomi",
"nópar",
"nópin",
"nópum",
"nópur",
"nóran",
"nørdi",
"nørdu",
"Noreg",
"nórin",
"nørir",
"norma",
"Norma",
"normi",
"norms",
"Normu",
"norna",
"nornu",
"Norra",
"norsk",
"nørst",
"nørta",
"nørti",
"nørts",
"nórum",
"nórur",
"nósan",
"nósar",
"nosið",
"nosin",
"nósin",
"nøsin",
"nøsku",
"nosna",
"nossa",
"nossi",
"nossu",
"nosum",
"nósum",
"nøsum",
"notan",
"nótan",
"nøtan",
"notar",
"nótar",
"nøtar",
"notat",
"notið",
"notin",
"nótin",
"nøtin",
"notir",
"nótir",
"nøtir",
"nøtra",
"notum",
"nótum",
"nøtum",
"notur",
"nøtur",
"nøvan",
"nøvin",
"nøvum",
"nøvur",
"noyða",
"noydd",
"noyði",
"noytt",
"nubba",
"nudla",
"nudlu",
"nugga",
"núgvu",
"núlig",
"nulla",
"nulli",
"nulva",
"numra",
"nunna",
"nunnu",
"núpar",
"núpin",
"núpum",
"núpur",
"nurtu",
"nussa",
"nussi",
"nútíð",
"nýára",
"nýari",
"nýári",
"nýárs",
"nyðra",
"nyðru",
"nýggi",
"nýggj",
"nykar",
"nykin",
"nykka",
"nykki",
"nykti",
"nyktu",
"nykum",
"nykur",
"nylon",
"nýøld",
"nýrað",
"nýrna",
"nýrum",
"nýrur",
"nýsur",
"nýtan",
"nýtar",
"nýtið",
"nýtíð",
"nýtir",
"nytra",
"nytru",
"nytta",
"nýtta",
"nytti",
"nýtti",
"nyttu",
"nýttu",
"nytum",
"nýtum",
"nytur",
"nýtur",
"nýval",
"nývil",
"nývla",
"nývli",
"o.fl.",
"óæran",
"óærut",
"óárið",
"óárum",
"óátið",
"óbata",
"óbati",
"óbein",
"óbend",
"óbent",
"oblat",
"óblíð",
"óbúna",
"óbúni",
"óbúnu",
"óburð",
"óbygd",
"óbygt",
"óbýla",
"óbýli",
"óbýtt",
"ódáða",
"ódæla",
"ódæll",
"ódælt",
"óðali",
"óðals",
"ódámi",
"ódáms",
"óðari",
"óðast",
"oddað",
"oddan",
"oddar",
"oddin",
"Oddný",
"oddum",
"oddur",
"Oddvá",
"øðina",
"Óðini",
"øðini",
"Óðins",
"ódjór",
"ódlig",
"ódnað",
"ódnar",
"ódnin",
"ódnir",
"ódnum",
"ódnur",
"ódøma",
"ódømi",
"øðrum",
"óduld",
"ódult",
"øðuna",
"ódygd",
"ódygg",
"ódygt",
"ódýra",
"ódýri",
"ódýrs",
"ódýrt",
"ódýru",
"óegnd",
"óegnt",
"óeins",
"óetið",
"óetin",
"óetna",
"ófala",
"ófalt",
"ófast",
"ófeig",
"ófest",
"offur",
"ófima",
"ófimt",
"ófimu",
"ófína",
"ófínt",
"ófitt",
"ófødd",
"óføra",
"óføri",
"óført",
"óføru",
"óføst",
"óføtt",
"ofrað",
"ofrar",
"ófría",
"ofrið",
"ófrið",
"ófríð",
"ofrum",
"ofsið",
"ofsin",
"ofsna",
"oftum",
"ófúsa",
"ófúst",
"ófylt",
"ógæva",
"ógævu",
"ógagn",
"ógáið",
"ógáin",
"ógána",
"ógerð",
"ógift",
"ógild",
"øgils",
"ógilt",
"ógitt",
"óglað",
"óglær",
"øglar",
"øglum",
"ognað",
"ognan",
"ognar",
"ognin",
"øgnin",
"ognir",
"ognum",
"øgnum",
"øgnut",
"ógvað",
"ógvar",
"ógvár",
"óhærd",
"óhært",
"óhaga",
"óhagt",
"óhapp",
"óhaps",
"óheft",
"óheil",
"óheld",
"óhent",
"óherd",
"óhert",
"ohmið",
"ohmum",
"óhøgi",
"óhógv",
"óhuga",
"óhugi",
"óhugs",
"óivað",
"ójøvn",
"okans",
"ókáta",
"ókátt",
"ókend",
"ókent",
"økils",
"øking",
"okini",
"økini",
"økist",
"økjum",
"okkum",
"óklár",
"øklar",
"óklók",
"øklum",
"oknar",
"oknin",
"oknir",
"oknum",
"ókøna",
"ókøni",
"ókønt",
"okrað",
"okrar",
"okrið",
"økrum",
"Økrum",
"ókrút",
"oksan",
"oksar",
"øksar",
"oksin",
"øksin",
"øksir",
"øksla",
"øksli",
"øksls",
"oksum",
"øksum",
"oktan",
"øktan",
"øktar",
"øktin",
"øktir",
"øktum",
"øktur",
"økuna",
"økuni",
"ókunn",
"ókunt",
"okurs",
"ókyrr",
"ókyrt",
"ølaða",
"ólærd",
"ólært",
"ólæst",
"ólæta",
"ólæti",
"ólagi",
"ólags",
"Ólavi",
"Ólavs",
"ølbúk",
"øldin",
"øldir",
"øldós",
"øldum",
"Olgar",
"ølger",
"ólíka",
"ólíkt",
"ólíku",
"ølina",
"ølini",
"ólitt",
"ólívs",
"oljan",
"óljóð",
"oljum",
"oljur",
"oljut",
"ølkík",
"øllum",
"ølóða",
"ólødd",
"óløga",
"óløgi",
"óløgs",
"ólønt",
"óløst",
"óløtt",
"ølótt",
"Olsen",
"ólúna",
"Óluva",
"Óluvu",
"ómælt",
"ómagi",
"ómaka",
"ómaki",
"ómaks",
"ómála",
"ómáli",
"omaná",
"ómegd",
"ómegi",
"ómild",
"ómilt",
"ómint",
"omman",
"ommum",
"ommur",
"ommut",
"ómóða",
"ómótt",
"ompil",
"ompla",
"ompli",
"ónáða",
"ónáði",
"ønara",
"ønari",
"óndan",
"óndar",
"ondin",
"óndir",
"óndum",
"óndur",
"óneyv",
"ongan",
"ongar",
"ongin",
"ongir",
"ongla",
"ongli",
"ongul",
"ongum",
"ønina",
"øning",
"ønini",
"onkis",
"onkra",
"onkun",
"onkur",
"onnur",
"ónøgd",
"ónøgt",
"ónøkt",
"ontin",
"øntir",
"ontum",
"øntur",
"ønuta",
"ónýta",
"ónýti",
"ónýtt",
"óorða",
"óorði",
"óorðs",
"opaða",
"opaði",
"øpaði",
"opaðu",
"øpaðu",
"opala",
"opali",
"opals",
"opast",
"opera",
"operu",
"opini",
"opium",
"opnað",
"opnan",
"opnar",
"opnir",
"opnum",
"opnur",
"opsið",
"opsin",
"opsna",
"opsni",
"optik",
"óráða",
"óraði",
"øraði",
"óraðu",
"øraðu",
"órædd",
"órætt",
"ørari",
"ordað",
"orðað",
"ordan",
"orðar",
"orðfá",
"orðið",
"orðna",
"ordra",
"ordri",
"ørðug",
"orðum",
"órdur",
"ørðut",
"órein",
"ørfáu",
"ørfín",
"organ",
"ørgan",
"orgla",
"orgli",
"orgul",
"ørgum",
"ørgur",
"ørgut",
"órína",
"órini",
"órins",
"órinu",
"orkað",
"orkan",
"orkar",
"ørkin",
"ørkum",
"ørlig",
"orlov",
"ormar",
"ormin",
"ormum",
"ørmum",
"ormur",
"ornað",
"ornar",
"ørnin",
"ørnir",
"ørnum",
"óróar",
"órødd",
"órógv",
"óróin",
"órøkt",
"óróna",
"órørd",
"órørt",
"órøtt",
"óróum",
"orrað",
"orran",
"orrar",
"orrið",
"orrin",
"orrum",
"ørska",
"ørsku",
"ørsmá",
"orsøk",
"órudd",
"óruds",
"órúma",
"órúmi",
"órúms",
"órund",
"órunt",
"órusl",
"ørval",
"ørvar",
"orvið",
"ørvin",
"orvum",
"ørvum",
"ørvur",
"ósádd",
"osaði",
"øsaði",
"osaðu",
"øsaðu",
"ósædd",
"ósæla",
"ósælu",
"ósærd",
"ósært",
"ósætt",
"ósagt",
"ósalt",
"ósamd",
"ósamt",
"osans",
"ósans",
"øsast",
"ósatt",
"ósátt",
"óseka",
"ósekt",
"óseku",
"óseld",
"óselt",
"ósett",
"øsgrá",
"ósiði",
"øsing",
"øsini",
"øsist",
"ósjón",
"øskan",
"Oskar",
"óskil",
"øskra",
"øsnað",
"øsnan",
"øsnar",
"øsnið",
"øsnum",
"øsnur",
"øsnut",
"ósøgd",
"ósøkt",
"ósølt",
"ósóma",
"ósømd",
"ósómi",
"ósonn",
"øspin",
"øspum",
"Øssur",
"ostar",
"ostin",
"óstøk",
"ostra",
"ostru",
"ostum",
"østum",
"ostur",
"østur",
"ósunn",
"ósunt",
"ósýnd",
"ósýni",
"ósýnt",
"otaði",
"otaðu",
"ótætt",
"ótald",
"ótali",
"ótals",
"ótalt",
"ótamd",
"ótamt",
"otana",
"øtana",
"øtast",
"ótent",
"øtist",
"øtlað",
"øtlar",
"ótódn",
"ótøka",
"ótøki",
"ótøkk",
"ótøkt",
"ótoli",
"ótols",
"ótørv",
"ótøtt",
"ótøvd",
"ótøvt",
"otrar",
"otrum",
"óttað",
"óttan",
"Ottar",
"óttar",
"óttin",
"ótugd",
"ótugt",
"oturs",
"ótýða",
"ótynt",
"ótyst",
"ótýtt",
"øvaði",
"øvaðu",
"óvana",
"óvand",
"óvani",
"óvant",
"ovara",
"óvard",
"ovari",
"óvart",
"ovaru",
"ovast",
"ováti",
"ováts",
"ovbit",
"øvdur",
"óvelt",
"oveta",
"ovfer",
"ovfór",
"øviga",
"óvígd",
"óvígt",
"øvigt",
"óvild",
"óvina",
"óvini",
"óvísa",
"óviss",
"óvist",
"óvíst",
"óvita",
"óviti",
"óvits",
"ovnar",
"ovnin",
"ovnum",
"ovnur",
"ovótu",
"ovróð",
"ovrør",
"ovtók",
"øvuga",
"øvugt",
"øvund",
"øvuta",
"øvutu",
"ovvit",
"oyðan",
"oyðát",
"oydda",
"oyddi",
"oyddu",
"oyðið",
"oyðin",
"oyðir",
"Oydis",
"oyðis",
"oydna",
"oydnu",
"oyðsl",
"oyðum",
"oyður",
"oygda",
"oyggj",
"oygla",
"Oygló",
"oynna",
"oynni",
"Oyolv",
"oyrað",
"oyran",
"oyrar",
"Oyrar",
"oyrin",
"oyrna",
"oyrum",
"oyrur",
"oysan",
"oysið",
"oysir",
"oysti",
"oystu",
"oysum",
"oysur",
"pábót",
"paðað",
"padda",
"paðið",
"Pætur",
"pakka",
"pakki",
"pakta",
"palla",
"Palla",
"palli",
"Palli",
"Pálli",
"Palma",
"pálma",
"Pálma",
"pálmi",
"Palmu",
"Pálmu",
"panda",
"pandu",
"panel",
"panna",
"pannu",
"panta",
"panti",
"pants",
"pápan",
"pápar",
"pápin",
"pappa",
"pappi",
"Papua",
"pápum",
"parað",
"parar",
"parið",
"Paris",
"parka",
"parta",
"parti",
"partí",
"parts",
"partú",
"páska",
"passa",
"passi",
"pasta",
"pastu",
"patos",
"Paula",
"Pauli",
"Paulu",
"pávan",
"pávar",
"pávin",
"pavna",
"pavni",
"pavns",
"pavst",
"pávum",
"pedal",
"pegil",
"pegla",
"pegli",
"peiga",
"peika",
"peiki",
"peila",
"peisa",
"pekan",
"pekum",
"pekur",
"pelan",
"pelar",
"pelin",
"pella",
"pelli",
"pelum",
"pelur",
"penan",
"penar",
"penga",
"pengi",
"penir",
"penis",
"penna",
"penni",
"pensa",
"penta",
"pentu",
"penum",
"penur",
"peran",
"perla",
"perli",
"perls",
"perlu",
"perma",
"permu",
"persa",
"persi",
"perum",
"perur",
"pesin",
"pesja",
"pesju",
"pesta",
"pesti",
"pests",
"Petra",
"Petru",
"petta",
"petti",
"Petur",
"phil.",
"piano",
"piðað",
"piðar",
"píkað",
"píkan",
"píkar",
"píkin",
"pikka",
"pikki",
"piksa",
"piksi",
"pikta",
"pikti",
"píkum",
"píkur",
"pílan",
"pílar",
"pílik",
"pílin",
"pílir",
"pilka",
"pilki",
"pilks",
"pílla",
"pílli",
"pilot",
"pilta",
"pilti",
"pilts",
"pílum",
"pílur",
"pimpa",
"pínað",
"pínan",
"pínar",
"pínda",
"píndi",
"píndu",
"pínir",
"pinka",
"pinki",
"pinks",
"pinku",
"pinna",
"pinni",
"pínum",
"pínur",
"pípað",
"pípan",
"pipar",
"pípar",
"pípil",
"pípla",
"pípli",
"pippa",
"pippi",
"pippu",
"pipra",
"pípum",
"pípur",
"píran",
"pirar",
"pírdi",
"pírdu",
"pírið",
"pirin",
"pírin",
"pírir",
"pirka",
"pirku",
"pírna",
"pírni",
"pirra",
"pirri",
"pirru",
"pirtl",
"pirum",
"pírum",
"pirur",
"pírur",
"pírut",
"pisan",
"píska",
"píski",
"písks",
"pissa",
"pissi",
"pístr",
"pisum",
"pisur",
"pitla",
"pitlu",
"pitsa",
"pitsu",
"pitta",
"pitti",
"pjága",
"pjaka",
"pjaki",
"pjaks",
"pjask",
"pjøka",
"pjøki",
"pjøks",
"pjøpp",
"pjøps",
"plaga",
"plága",
"plagg",
"plagi",
"plags",
"plagt",
"plágu",
"plana",
"plani",
"plans",
"pláss",
"plast",
"pláta",
"plats",
"platt",
"plátu",
"plena",
"plenu",
"plets",
"plett",
"pleyr",
"pliað",
"pliar",
"plikt",
"plink",
"plint",
"pløga",
"pløgd",
"pløgg",
"plógs",
"pløgt",
"plógv",
"pluss",
"plúss",
"poesi",
"pokan",
"pokar",
"pokna",
"poknu",
"poksi",
"pokum",
"pokur",
"pokut",
"polar",
"pólar",
"pólin",
"polio",
"pólís",
"polka",
"polla",
"polli",
"polsk",
"pólsk",
"pólum",
"pólur",
"poppi",
"porno",
"pørum",
"posan",
"posar",
"pøsar",
"posin",
"pøsin",
"pøsir",
"posta",
"pøsta",
"posti",
"pøsti",
"posts",
"pøstu",
"posum",
"pøsum",
"posut",
"potað",
"potar",
"potið",
"potta",
"potti",
"potum",
"Poula",
"Pouli",
"Pouls",
"Poulu",
"prála",
"práli",
"práls",
"práma",
"prámi",
"práms",
"prása",
"prási",
"práta",
"práti",
"práts",
"preia",
"preii",
"preis",
"prena",
"preni",
"prens",
"prent",
"press",
"prest",
"prika",
"priki",
"prikk",
"priks",
"príla",
"príli",
"príls",
"prins",
"pripp",
"prips",
"prísa",
"prísi",
"prógv",
"próna",
"próni",
"próns",
"propp",
"props",
"prosa",
"próst",
"prøva",
"prøvi",
"próvs",
"proys",
"prúða",
"prúdd",
"prúði",
"prúðu",
"prump",
"prunk",
"prusa",
"prusi",
"prutl",
"prútt",
"prýða",
"prýdd",
"prýði",
"prýtt",
"p-tal",
"p-tøl",
"puaði",
"puaðu",
"puffa",
"puffi",
"púkan",
"púkar",
"púkin",
"púkum",
"púlað",
"púlar",
"púlið",
"pulla",
"pulli",
"pulsi",
"pulta",
"pulti",
"pults",
"púlum",
"pumma",
"pumpa",
"pumpu",
"pumsa",
"pumsi",
"punda",
"pundi",
"punds",
"punga",
"pungi",
"pungs",
"punkt",
"punsa",
"punsi",
"punta",
"punti",
"punts",
"púran",
"púrar",
"púrga",
"púrir",
"purka",
"purla",
"purlu",
"purra",
"purru",
"púrum",
"púrur",
"púsið",
"púsin",
"púsna",
"pussa",
"pussi",
"pústr",
"pútan",
"putla",
"putli",
"putls",
"putlu",
"putra",
"putri",
"putta",
"putti",
"pútum",
"putur",
"pútur",
"puuta",
"pýggi",
"pýggj",
"pyksa",
"pyksi",
"pylsa",
"pylsu",
"pynta",
"pynti",
"pynts",
"pýnum",
"ráans",
"ráari",
"rabat",
"rabbi",
"rábít",
"raðað",
"raðan",
"radar",
"raðar",
"ráðar",
"radda",
"rádda",
"ráddi",
"ráddu",
"raðið",
"ráðið",
"ráðin",
"radio",
"raðir",
"rádna",
"rádni",
"raðum",
"ráðum",
"rádýr",
"rædda",
"ræddi",
"ræddu",
"ræðið",
"ræðir",
"ræðis",
"ræðst",
"ræðum",
"ræður",
"rædýr",
"ræini",
"rækja",
"rækju",
"rælað",
"rælar",
"rælin",
"rælir",
"rælti",
"ræltu",
"rælum",
"rælur",
"ræmdi",
"ræmdu",
"ræmir",
"rænda",
"rændi",
"rændu",
"rænir",
"rænum",
"ræsið",
"ræsir",
"ræsis",
"ræsna",
"ræsta",
"ræsti",
"ræstu",
"ræsum",
"ræsur",
"rætna",
"rætta",
"rætti",
"rættu",
"rævri",
"rævur",
"rafla",
"ráfræ",
"rafta",
"ragga",
"raggi",
"ragla",
"ragna",
"Ragna",
"Ragnu",
"ráina",
"ráini",
"rakað",
"rakær",
"rakan",
"rákan",
"rakar",
"rakár",
"rakið",
"rákið",
"rákin",
"rákís",
"rakka",
"rakki",
"rakna",
"rakni",
"rakst",
"rakta",
"rakti",
"raktu",
"Rakul",
"rakum",
"rákum",
"ralað",
"ralar",
"rálig",
"rámað",
"raman",
"ráman",
"ramar",
"rámar",
"rámið",
"rámin",
"ramir",
"ramma",
"rammi",
"rammu",
"rampa",
"rampi",
"rampu",
"ramsa",
"ramsi",
"ramsu",
"rámti",
"rámtu",
"ramum",
"rámum",
"ramur",
"rámur",
"ránað",
"ranan",
"ranar",
"ránar",
"randa",
"Randi",
"ranfa",
"ranfu",
"ranga",
"rangt",
"rangu",
"ránið",
"ranin",
"ránir",
"ranka",
"ranki",
"rankt",
"ranna",
"ránna",
"ranni",
"ránni",
"ránsk",
"ranst",
"ránti",
"rántu",
"ranum",
"ránum",
"rapað",
"rapar",
"rapla",
"rapli",
"rapls",
"rappa",
"rappi",
"rapul",
"ráran",
"rárar",
"rárir",
"rárum",
"rárur",
"rasað",
"rásan",
"rasar",
"rásar",
"rásin",
"rásir",
"raska",
"raski",
"raskt",
"raspa",
"raspi",
"rasps",
"rassa",
"rassi",
"rasum",
"rásum",
"ravan",
"ravar",
"ravið",
"rávið",
"ravin",
"ravna",
"ravni",
"ravns",
"ravum",
"redar",
"redda",
"redik",
"redin",
"redir",
"reðka",
"reðku",
"reðra",
"reðri",
"redum",
"reður",
"Regin",
"regla",
"reglu",
"regna",
"regni",
"regns",
"reiða",
"reidd",
"reiði",
"reiðu",
"reigs",
"reika",
"reiki",
"reiks",
"reina",
"reini",
"reint",
"reinu",
"reion",
"reipa",
"reipi",
"reips",
"reisa",
"reisi",
"reist",
"reisu",
"reita",
"reitt",
"reiva",
"reivi",
"reivs",
"rekan",
"rekar",
"rekið",
"rekil",
"rekin",
"rekir",
"rekja",
"rekka",
"rekki",
"rekla",
"rekli",
"reksa",
"rekst",
"reksu",
"rekti",
"rektu",
"rekum",
"rekur",
"remba",
"rembi",
"rembt",
"remma",
"remmu",
"remsa",
"remsu",
"renda",
"rendi",
"rends",
"rendu",
"renið",
"renna",
"renni",
"rennu",
"rensl",
"renta",
"rentu",
"repað",
"repar",
"repið",
"repil",
"repla",
"repli",
"reppa",
"reppi",
"repum",
"resta",
"retur",
"revar",
"revil",
"revin",
"revla",
"revli",
"revsa",
"revsi",
"revum",
"revur",
"reyða",
"reyði",
"reyðu",
"reyka",
"reyki",
"reyks",
"reyla",
"reyna",
"reyni",
"reyns",
"reypa",
"reypi",
"reyps",
"reyst",
"reytt",
"reyva",
"ribes",
"ribsa",
"ribsi",
"riðað",
"riðan",
"riðar",
"riðið",
"riðil",
"riðin",
"ríðir",
"riðja",
"ridla",
"riðla",
"riðli",
"riðul",
"riðum",
"ríðum",
"riður",
"ríður",
"rifla",
"riflu",
"rigga",
"rigna",
"rígna",
"rignt",
"ríkað",
"ríkan",
"ríkar",
"rikið",
"ríkið",
"rikin",
"ríkir",
"ríkis",
"ríkja",
"rikna",
"rikni",
"riknu",
"ríkti",
"ríktu",
"ríkum",
"ríkur",
"rilla",
"rilta",
"rimað",
"rímað",
"ríman",
"rimar",
"rímar",
"rímið",
"rimin",
"rimpa",
"rimum",
"rímum",
"rímur",
"rinað",
"rínan",
"rinar",
"Rínar",
"rinda",
"rindi",
"rindu",
"ringa",
"ringd",
"ringi",
"rings",
"ringt",
"ringu",
"rinið",
"rinin",
"Rínin",
"rínir",
"rinka",
"rínsk",
"rinum",
"rínur",
"ripað",
"rípan",
"ripar",
"ripið",
"rippa",
"rippu",
"ripsa",
"ripsi",
"ripti",
"riptu",
"ripum",
"rípum",
"rípur",
"ríput",
"risan",
"risar",
"risið",
"rísið",
"risin",
"rísin",
"riska",
"riski",
"risks",
"riskt",
"risla",
"rísla",
"risna",
"rísna",
"rísnu",
"rispa",
"rista",
"risti",
"rists",
"ristu",
"risum",
"rísum",
"rísur",
"ritað",
"ritan",
"ritar",
"ritið",
"ritil",
"ritli",
"ritna",
"ritum",
"ritur",
"ritut",
"rivað",
"rivan",
"rívan",
"rivar",
"rivið",
"rivin",
"rivja",
"rivju",
"rivna",
"rivnu",
"rivum",
"rívum",
"rivur",
"rívur",
"rivut",
"rjóða",
"rjóta",
"Róald",
"Róari",
"Róars",
"roðað",
"roðál",
"roðan",
"røðan",
"roðar",
"rodda",
"rødda",
"roddi",
"røddi",
"roðið",
"røðil",
"roðin",
"røðin",
"røðir",
"røðla",
"røðli",
"rodna",
"rodnu",
"róðra",
"røðra",
"róðri",
"røðum",
"róður",
"røður",
"røðut",
"rogna",
"rogni",
"rogns",
"rógva",
"Rógva",
"rógvi",
"Rógvi",
"rógvu",
"róina",
"róini",
"rokað",
"rokan",
"røkan",
"rokar",
"rokár",
"rókar",
"rokið",
"røkið",
"røkil",
"rokin",
"rókin",
"røkin",
"røkir",
"røkja",
"røkju",
"rokka",
"røkka",
"rokki",
"røkki",
"røkla",
"røkli",
"rokna",
"røkna",
"rokni",
"roksa",
"roksi",
"røkta",
"røkti",
"røktu",
"rokum",
"rókum",
"røkum",
"rókur",
"Rókur",
"røkur",
"rokut",
"rókut",
"rólað",
"rólag",
"rólan",
"rólar",
"rólið",
"rólig",
"rølik",
"rolla",
"rollu",
"róløg",
"Rólvi",
"Rólvs",
"rómað",
"róman",
"rómar",
"Rómar",
"rómin",
"Rómin",
"rommi",
"romum",
"rómur",
"rongt",
"rongu",
"Ronja",
"Ronju",
"rónna",
"rónni",
"ronta",
"rønta",
"ronti",
"ronts",
"rónum",
"rópað",
"ropan",
"rópan",
"ropar",
"rópar",
"ropið",
"rópið",
"ropin",
"roppa",
"rópti",
"róptu",
"ropum",
"rópum",
"rørar",
"rørda",
"rørdi",
"rørdu",
"rørið",
"rørir",
"rørum",
"rósað",
"rósan",
"rósar",
"rósið",
"rósin",
"røsku",
"rossa",
"rossi",
"røsta",
"rósti",
"róstu",
"rósum",
"rósur",
"rósut",
"rotað",
"rótað",
"rotan",
"rótan",
"rotar",
"rótar",
"rotið",
"rotin",
"rótin",
"rotna",
"rotnu",
"rotor",
"rotta",
"rotti",
"rottu",
"røttu",
"rotum",
"rótum",
"røtur",
"røvað",
"rovan",
"røvar",
"røvil",
"røvla",
"røvli",
"rovum",
"royða",
"royðu",
"royki",
"royks",
"roykt",
"royna",
"roynd",
"royni",
"roynt",
"royra",
"royrt",
"royta",
"royti",
"roytt",
"royva",
"royvi",
"royvt",
"rubba",
"Rubek",
"rubil",
"rubin",
"rubla",
"rubli",
"rubul",
"rudda",
"ruddi",
"ruddu",
"rúðri",
"rúður",
"ruffa",
"ruffi",
"rufla",
"rugga",
"rugin",
"rugur",
"rúgva",
"rúgvu",
"rukka",
"rukki",
"rukku",
"rulka",
"rulki",
"rulks",
"rulla",
"rulli",
"rullu",
"rúmað",
"rúman",
"rúmar",
"rúmár",
"rúmda",
"rúmið",
"rúmir",
"rúmka",
"rumla",
"rumpa",
"rumpu",
"rumsa",
"rúmti",
"rúmtu",
"rúmum",
"rúmur",
"rúnað",
"runan",
"runar",
"rúnar",
"Rúnar",
"runda",
"rundi",
"rundu",
"runga",
"rungi",
"rungs",
"runið",
"runin",
"rúnin",
"rúnir",
"runna",
"runni",
"runnu",
"runum",
"rúnum",
"rúnur",
"runut",
"rupla",
"rupli",
"rupls",
"rupul",
"rurað",
"ruran",
"rurar",
"rúsað",
"rúsan",
"rúsar",
"rúsin",
"rúsir",
"ruska",
"ruski",
"rusks",
"rusla",
"rusli",
"rusls",
"ruspa",
"ruspi",
"rusps",
"russa",
"russi",
"rusta",
"rusti",
"rusts",
"rúsum",
"rúsur",
"rusut",
"rutan",
"Rutar",
"rútar",
"rutið",
"rútin",
"rútir",
"rútmu",
"rutum",
"rútum",
"rutur",
"rútur",
"rútut",
"rýddi",
"rýddu",
"rýðið",
"rýðir",
"ryðja",
"ryður",
"rygdi",
"rygdu",
"ryggi",
"rygna",
"rygnt",
"rykka",
"rykki",
"rykta",
"rykti",
"ryktu",
"rýkur",
"rylkt",
"rýmdi",
"rýmdu",
"rýmið",
"rýmir",
"rymja",
"rymur",
"rýpan",
"rýpum",
"rýpur",
"ryski",
"ryssa",
"ryssu",
"rytan",
"rytma",
"rýtma",
"rytmu",
"rýtmu",
"rytta",
"ryttu",
"rytum",
"rytur",
"rýtur",
"ryvja",
"ryvju",
"sáaði",
"sáaðu",
"sáast",
"sáðan",
"sáðar",
"sáddi",
"sáddu",
"sáðið",
"saðil",
"sáðin",
"sáðir",
"saðla",
"sádóm",
"sáðum",
"sáður",
"sáðut",
"sæddu",
"Sædis",
"sækúm",
"sækýr",
"sælan",
"sælda",
"sældi",
"sælds",
"sældu",
"sælum",
"sælur",
"særda",
"særdi",
"særdu",
"særir",
"sætað",
"sætar",
"sætið",
"sætir",
"sætis",
"sætta",
"sætti",
"sættu",
"sætum",
"Sæunn",
"sævar",
"safir",
"safta",
"sagað",
"sagan",
"sagar",
"sagda",
"sagin",
"sagir",
"sagna",
"sagum",
"sáing",
"sakað",
"sakan",
"sakar",
"sakin",
"sakir",
"sakna",
"sakni",
"sakns",
"saksa",
"saksi",
"saksu",
"sakta",
"sakum",
"sálað",
"salar",
"sálar",
"salat",
"sálda",
"sáldi",
"saldo",
"sálds",
"sáldu",
"salgi",
"salgs",
"sálig",
"salin",
"sálin",
"salir",
"sálir",
"salla",
"salli",
"sálma",
"sálmi",
"sálms",
"Salný",
"salón",
"salta",
"salti",
"salts",
"sálug",
"salum",
"sálum",
"salur",
"salva",
"salvu",
"samal",
"Sámal",
"saman",
"sáman",
"samar",
"sámar",
"samda",
"samdi",
"sámdi",
"samdu",
"sámdu",
"samið",
"sámin",
"samir",
"sámir",
"samis",
"samla",
"Sámoa",
"sámoi",
"Sámua",
"sámum",
"samur",
"sanda",
"sandi",
"Sandi",
"sands",
"Sands",
"sanga",
"sangi",
"sangs",
"sanka",
"sanki",
"sanks",
"sanna",
"Sanna",
"sanni",
"Sannu",
"sansa",
"sansi",
"santa",
"sápað",
"sápan",
"sápar",
"sápum",
"sápur",
"sáran",
"sárar",
"sárið",
"sárir",
"sárka",
"sárki",
"sárum",
"sárur",
"sátan",
"satsa",
"satsi",
"sátta",
"sátum",
"sátur",
"Saudi",
"saval",
"savna",
"savni",
"savns",
"sbrt.",
"sebra",
"sebru",
"seðil",
"seðla",
"seðli",
"segði",
"seggi",
"segla",
"segli",
"segls",
"seiða",
"seiði",
"seiðs",
"seiga",
"seigs",
"seigt",
"seigu",
"seina",
"seint",
"seinu",
"sekan",
"sekar",
"sekir",
"sekki",
"seksu",
"sekta",
"sekum",
"sekur",
"selan",
"selar",
"selda",
"seldi",
"seldu",
"selin",
"selir",
"selja",
"selji",
"sello",
"Selma",
"Selmu",
"selst",
"selum",
"selur",
"semja",
"semju",
"semur",
"senat",
"senda",
"sendi",
"sendu",
"Seoul",
"sept.",
"seran",
"serar",
"serba",
"serbi",
"seria",
"serir",
"seriu",
"serki",
"serum",
"serur",
"serva",
"servu",
"sesam",
"sessa",
"sessi",
"setan",
"setar",
"setið",
"setin",
"setir",
"setra",
"setri",
"setst",
"setta",
"setti",
"settu",
"setum",
"setur",
"sevið",
"sevil",
"sevja",
"sevju",
"sevla",
"sevli",
"sevum",
"seyða",
"seyði",
"seyma",
"seymi",
"seyms",
"seyra",
"seyri",
"seysa",
"sheik",
"sibba",
"sibbi",
"siðað",
"siðan",
"síðan",
"siðar",
"síðar",
"sídda",
"siðin",
"siðir",
"síðir",
"síðis",
"síðka",
"síðki",
"síðla",
"Sidon",
"síðst",
"siðum",
"síðum",
"siður",
"síður",
"siftu",
"sigar",
"sigda",
"sigga",
"Sigga",
"síggi",
"síggj",
"siggu",
"Siggu",
"sigið",
"sigin",
"sigla",
"sigld",
"sigli",
"siglt",
"sigma",
"signa",
"Signa",
"signi",
"Signu",
"Signý",
"sigra",
"sigri",
"sigst",
"sigul",
"sigum",
"sigur",
"sígur",
"sikta",
"sikti",
"silað",
"sílað",
"sílan",
"silar",
"sílar",
"Silas",
"silda",
"silið",
"sílið",
"sílin",
"sílir",
"silja",
"Silja",
"silju",
"Silju",
"silki",
"silpa",
"silpu",
"silum",
"sílum",
"sílur",
"silvu",
"Símun",
"Sinai",
"sinan",
"sinar",
"sínar",
"sinin",
"sínir",
"sinka",
"sinki",
"sinks",
"sinna",
"sinni",
"sinop",
"sinta",
"sinum",
"sínum",
"sinur",
"sipað",
"sipan",
"sipar",
"sipið",
"sípot",
"sipum",
"sipur",
"Sirið",
"sirja",
"sirju",
"sirma",
"sirmi",
"sirms",
"sirop",
"sirra",
"sirru",
"sirsi",
"sirum",
"sissa",
"sitat",
"sitið",
"sitin",
"sítræ",
"sítrø",
"situr",
"Sívar",
"sivil",
"sivja",
"sjabb",
"sjaga",
"sjagg",
"sjags",
"sjakk",
"sjaks",
"sjala",
"sjála",
"sjali",
"sjáli",
"sjals",
"sjáls",
"sjálv",
"sjang",
"sjarr",
"sjars",
"sjask",
"sjava",
"sjavi",
"sjavs",
"sjefa",
"sjefi",
"sjefs",
"sjeik",
"sjela",
"sjeyu",
"sjóða",
"sjóði",
"sjóðs",
"sjógv",
"sjóna",
"sjónd",
"sjong",
"sjøta",
"sjøti",
"sjøts",
"sjúka",
"sjúki",
"sjúkt",
"sjúku",
"Sjúrð",
"sjuss",
"skáað",
"skáan",
"skáar",
"skaða",
"skadd",
"skaði",
"skæra",
"skæri",
"skærs",
"skært",
"skæru",
"skaff",
"skafs",
"skaft",
"Skaga",
"Skagi",
"skáið",
"skáin",
"skaka",
"skáka",
"skaki",
"skáki",
"skakk",
"skaks",
"skáks",
"skakt",
"skala",
"skála",
"Skála",
"skald",
"skáld",
"skali",
"skáli",
"Skáli",
"skálk",
"skálm",
"skals",
"skalt",
"skalv",
"skamm",
"skams",
"skamt",
"skank",
"skapa",
"skápa",
"skapi",
"skápi",
"skaps",
"skáps",
"skapt",
"skara",
"skára",
"skarð",
"Skarð",
"skari",
"skarn",
"skarp",
"skart",
"skarv",
"skata",
"skatt",
"skatu",
"skáum",
"skava",
"skavi",
"skavs",
"skeft",
"skegg",
"skegs",
"skeið",
"skein",
"skeit",
"skeiv",
"skeka",
"skeki",
"skeld",
"skelk",
"skell",
"skelm",
"skels",
"skelt",
"skema",
"skemd",
"skemt",
"skera",
"skerd",
"skeri",
"skerm",
"skers",
"skert",
"skerv",
"skeyt",
"skíða",
"skíði",
"skift",
"skikk",
"skiks",
"skila",
"skild",
"skili",
"skilp",
"skils",
"skilt",
"skima",
"skimi",
"skiml",
"skina",
"skína",
"skini",
"skinn",
"skins",
"skinu",
"skipa",
"skipi",
"skips",
"skíra",
"skírd",
"skíri",
"skírn",
"skírt",
"skíta",
"skíti",
"skitl",
"skits",
"skitt",
"skitu",
"skiva",
"skivu",
"skjal",
"skját",
"skjól",
"skjøl",
"skjør",
"skjót",
"skoða",
"skøða",
"skødd",
"skoði",
"skøði",
"skøft",
"skóga",
"skógi",
"skógv",
"skóka",
"skøkk",
"skola",
"skøld",
"skoli",
"skølm",
"skólp",
"skølp",
"skols",
"skolu",
"skomm",
"skona",
"skóna",
"skonk",
"skons",
"skopa",
"skøpt",
"skora",
"skøra",
"skørð",
"skori",
"skøri",
"skørp",
"skørr",
"skørs",
"skort",
"skørt",
"skoru",
"skóru",
"skøru",
"skósa",
"skota",
"skóta",
"skøta",
"skoti",
"skóti",
"skots",
"skott",
"skøtt",
"skøtu",
"skóva",
"skøva",
"skøvt",
"skóvu",
"skøvu",
"skoyt",
"skráa",
"skræð",
"skrái",
"skrál",
"skrán",
"skráp",
"skrev",
"skrið",
"skríð",
"skrín",
"skriv",
"skról",
"skróv",
"skrúð",
"skruv",
"skrúv",
"skúgv",
"skula",
"skúla",
"skuld",
"skúli",
"skulu",
"skúlv",
"skúma",
"skúmi",
"skump",
"skúms",
"skund",
"skura",
"skúra",
"skurð",
"skuri",
"skúri",
"skurr",
"skurs",
"skúrs",
"skurv",
"skuta",
"skúta",
"skuti",
"skúti",
"skutu",
"skútu",
"skúva",
"skúvi",
"skúvs",
"skver",
"skygd",
"skygt",
"skýið",
"skýla",
"skyld",
"skýli",
"skylt",
"skýlt",
"skýlu",
"skýma",
"skýmd",
"skýmt",
"skyna",
"skyni",
"skyns",
"skýra",
"skyri",
"skyrs",
"skýrt",
"skýum",
"sláar",
"slabb",
"slabs",
"slært",
"slætt",
"slafs",
"slaga",
"slagi",
"slags",
"sláið",
"sláin",
"sláir",
"slaka",
"slaki",
"slakk",
"slakt",
"slána",
"slang",
"sláni",
"slank",
"sláns",
"slapp",
"slaps",
"slátt",
"sláum",
"slava",
"sláva",
"slavi",
"slávi",
"slavs",
"slavt",
"sleða",
"sleðu",
"sleip",
"sleit",
"sleiv",
"slekt",
"slena",
"slepp",
"slept",
"sleta",
"slets",
"slett",
"sletu",
"sleva",
"slevi",
"slevj",
"slevs",
"slevu",
"sleyg",
"slíka",
"slikk",
"slíkt",
"slími",
"slíms",
"slipa",
"slípa",
"slips",
"slipu",
"slisk",
"slíta",
"sliti",
"slíti",
"slits",
"slitu",
"slóar",
"slóða",
"sløða",
"slødd",
"sløði",
"sløðr",
"sløðs",
"sløga",
"slógs",
"sløgt",
"slógu",
"slógv",
"slóin",
"slóir",
"sloka",
"sløka",
"sløki",
"slokk",
"sloks",
"sløks",
"slokt",
"sløkt",
"sloku",
"sløku",
"slóma",
"slómu",
"sløra",
"sløri",
"slørs",
"slota",
"sloti",
"slots",
"slott",
"slótt",
"sløtt",
"slóum",
"slufs",
"slúka",
"slúki",
"slúks",
"slúkt",
"slupp",
"slupr",
"slups",
"slúra",
"slúri",
"slurk",
"slúrs",
"slúsa",
"slúsu",
"slúta",
"slysa",
"slysi",
"slysn",
"smáað",
"smáan",
"smáar",
"smæða",
"smædd",
"smæið",
"smæin",
"smæra",
"smæru",
"smæst",
"smætt",
"smáir",
"smaka",
"smakk",
"smaks",
"smala",
"smali",
"small",
"smalt",
"smalu",
"smart",
"smask",
"smatt",
"smátt",
"smáum",
"smáur",
"smbr.",
"smeit",
"smekk",
"smeks",
"smell",
"smels",
"smelt",
"smeyg",
"smiða",
"smíða",
"smiði",
"smíði",
"smiðs",
"smíðs",
"smíla",
"smíli",
"smíls",
"smílt",
"smíri",
"smoga",
"smogi",
"smogu",
"smøks",
"smolk",
"smolt",
"smølu",
"smøri",
"smørs",
"smørt",
"smsið",
"smsum",
"smugu",
"smulk",
"smurd",
"smurt",
"smusk",
"smyrt",
"snáða",
"snáka",
"snáki",
"snakk",
"snaks",
"snáks",
"snáku",
"snaps",
"snara",
"snari",
"snart",
"snaru",
"snasa",
"snata",
"snáva",
"snávu",
"sneið",
"sneis",
"snerk",
"snert",
"sneyt",
"sniða",
"sníða",
"sniði",
"sniðs",
"sníka",
"sniki",
"sníki",
"sníks",
"sníkt",
"snild",
"snill",
"snils",
"snilt",
"snípa",
"snípi",
"snipp",
"snips",
"snípu",
"snirr",
"snirs",
"snjúð",
"snjýr",
"snobb",
"snobs",
"snoða",
"snodd",
"snoði",
"snoðs",
"snøgg",
"snøgt",
"snopp",
"snops",
"snora",
"snóra",
"snøra",
"snørd",
"snori",
"snóri",
"snøri",
"snork",
"snørp",
"snors",
"snórs",
"snørt",
"snóta",
"snóti",
"snóts",
"snudd",
"snúði",
"snuds",
"snúðu",
"snúgv",
"snúið",
"snúin",
"snúna",
"snúsa",
"snúsi",
"snúta",
"snúti",
"snýða",
"snýði",
"snyka",
"snyki",
"snyks",
"snýsa",
"snýsi",
"snýst",
"snýta",
"snýti",
"snýts",
"snýtt",
"sodað",
"sodan",
"soðan",
"soðið",
"soðin",
"sodna",
"sofan",
"Sofía",
"Sofíu",
"sofum",
"sofur",
"Sofus",
"sogan",
"søgan",
"søgdu",
"sogið",
"sogin",
"søgum",
"sogur",
"søgur",
"søkan",
"søkið",
"søkin",
"søkir",
"søkja",
"sokka",
"søkka",
"sokki",
"søkki",
"sokna",
"sókna",
"søkni",
"soknu",
"søkta",
"søkti",
"søktu",
"søkum",
"søkur",
"sólað",
"sølað",
"sølan",
"sólar",
"sólár",
"sølar",
"soldi",
"sølið",
"sólin",
"sólir",
"sólja",
"sólju",
"solka",
"søltu",
"sólum",
"sølum",
"sólur",
"sølur",
"Sólvá",
"Sølva",
"Sølvi",
"sóman",
"sømda",
"sømdi",
"sømdu",
"sómin",
"sømir",
"somlu",
"somul",
"somum",
"sónað",
"sonar",
"sondu",
"songa",
"songi",
"songs",
"sonin",
"Sonja",
"Sonju",
"Sonna",
"Sonni",
"sonnu",
"Sonnu",
"sonum",
"sonur",
"sópað",
"sopan",
"sopar",
"sópar",
"sopið",
"sópið",
"søpil",
"sopin",
"søpla",
"søpli",
"soppa",
"soppi",
"soppu",
"søpul",
"sopum",
"sorað",
"sørað",
"soran",
"søran",
"sorar",
"sørar",
"sorga",
"sorið",
"sorin",
"Sørin",
"sørir",
"sorla",
"sorli",
"sorls",
"sorna",
"sorni",
"sorns",
"sørpa",
"sørpu",
"sorta",
"sorti",
"sortu",
"sørum",
"sørur",
"sorut",
"sósar",
"sósin",
"sósir",
"sósum",
"sótað",
"søtað",
"søtan",
"sótar",
"søtar",
"sótið",
"søtin",
"søtir",
"sótta",
"søtum",
"søtur",
"sótut",
"sóust",
"søvdi",
"søvdu",
"sovið",
"søvir",
"sovna",
"sovni",
"soyað",
"soyða",
"soyði",
"soyla",
"soylu",
"soyma",
"soymd",
"soymi",
"soymt",
"soyra",
"soyrt",
"spáað",
"spáar",
"spádd",
"spæið",
"spæla",
"spæld",
"spæli",
"spæls",
"spælt",
"spæta",
"spætu",
"spaka",
"spáka",
"spaki",
"spáki",
"spáks",
"spakt",
"spann",
"spara",
"spard",
"spari",
"spark",
"sparl",
"spars",
"spart",
"spátt",
"spegl",
"speia",
"speii",
"speis",
"speki",
"spekt",
"spela",
"spell",
"spels",
"spenn",
"spent",
"sperd",
"sperm",
"spert",
"spika",
"spiki",
"spiks",
"spíla",
"spíld",
"spíli",
"spill",
"spilt",
"spina",
"spini",
"spíra",
"spíri",
"spírs",
"spíru",
"spísk",
"spíss",
"spjað",
"spjót",
"split",
"spóka",
"spóki",
"spóks",
"spøkt",
"spola",
"spøla",
"spoli",
"spøli",
"spøls",
"spóna",
"spong",
"spóni",
"sponn",
"spora",
"spøra",
"spori",
"spørk",
"sporl",
"spors",
"sport",
"spots",
"spott",
"spræn",
"spray",
"sprek",
"sprit",
"spryt",
"spula",
"spuna",
"spuni",
"spurd",
"spurt",
"spurv",
"sputs",
"sputt",
"spýði",
"spýðu",
"spyrt",
"spýta",
"spýti",
"spýts",
"spýtt",
"staða",
"stadd",
"staði",
"staðs",
"stæla",
"stæld",
"stælt",
"stætt",
"staka",
"stáka",
"staki",
"stáki",
"stakk",
"staks",
"stáks",
"stakt",
"staku",
"stála",
"stáli",
"stall",
"stáls",
"stama",
"stamp",
"stand",
"stang",
"stapa",
"stapi",
"stara",
"stari",
"stars",
"start",
"starv",
"stása",
"stási",
"stata",
"státa",
"stati",
"státi",
"stats",
"státs",
"statt",
"stava",
"stavi",
"stavn",
"stavs",
"steðg",
"steig",
"steik",
"stein",
"stelk",
"stell",
"stels",
"stelt",
"stemt",
"stepa",
"stepi",
"stepp",
"steps",
"sterk",
"stert",
"steva",
"stevi",
"stevn",
"stevs",
"steyp",
"steyr",
"stiði",
"stift",
"stiga",
"stíga",
"stigi",
"stígi",
"stigs",
"stígs",
"stigu",
"stika",
"stiki",
"stikk",
"stikl",
"stiks",
"stíla",
"stild",
"stíli",
"still",
"stíls",
"stilt",
"stima",
"stimi",
"Stina",
"sting",
"stinn",
"stint",
"Stinu",
"stird",
"stirt",
"stíva",
"stívt",
"stívu",
"stjal",
"stjól",
"stjøl",
"stóða",
"støða",
"stødd",
"støðg",
"stóði",
"støði",
"stóðs",
"stóðu",
"støðu",
"stokk",
"støkk",
"stoks",
"støks",
"stokt",
"støkt",
"støku",
"stóla",
"stóli",
"stóls",
"stolt",
"stong",
"stopp",
"stops",
"stóra",
"stórá",
"støra",
"stóri",
"støri",
"stork",
"storm",
"størs",
"stórt",
"stóru",
"størv",
"stóta",
"stóti",
"stóts",
"støtt",
"stova",
"støva",
"støvi",
"stovn",
"støvs",
"stovu",
"stoyt",
"stráa",
"strái",
"strát",
"strev",
"stríð",
"strik",
"strok",
"strúk",
"strút",
"studd",
"stuðl",
"stúgv",
"stúka",
"stúku",
"stumm",
"stump",
"stumt",
"stuna",
"stund",
"stuni",
"stuns",
"stunt",
"stúra",
"stúri",
"stúrs",
"stúrt",
"stúta",
"stutt",
"stútt",
"stútu",
"stúva",
"stúvi",
"stúvs",
"stygd",
"stygg",
"stygt",
"stynj",
"stýra",
"stýrd",
"stýri",
"styrk",
"stýrt",
"stytt",
"stýva",
"stývt",
"subba",
"subbi",
"suðað",
"Sudan",
"suðan",
"suðar",
"súðar",
"suðið",
"súðin",
"súðir",
"suðri",
"suðum",
"súðum",
"suður",
"suffa",
"suffi",
"súgað",
"súgál",
"súgar",
"sugga",
"suggu",
"súgið",
"súgin",
"súgur",
"súgva",
"súgvi",
"sukat",
"sukka",
"sukku",
"sukra",
"sukri",
"sukur",
"súlan",
"sulka",
"sulki",
"sulks",
"súlta",
"súltu",
"súlum",
"súlur",
"Sumba",
"summa",
"summi",
"sumra",
"sumri",
"sunda",
"sundi",
"Sundi",
"sunds",
"Sunds",
"sungu",
"sunna",
"súnna",
"súnni",
"sunnu",
"súnum",
"súpan",
"suppa",
"suppu",
"súran",
"súrar",
"súrga",
"súrgu",
"súrir",
"surka",
"surki",
"surks",
"surku",
"surla",
"surli",
"surls",
"súrna",
"surpa",
"surpu",
"surra",
"surri",
"surru",
"súrum",
"súrur",
"súsað",
"súsar",
"súsið",
"sussa",
"sussi",
"sussu",
"sútar",
"sútin",
"sútir",
"sutla",
"sutli",
"sutls",
"sutta",
"suttu",
"sútum",
"svaði",
"svæva",
"svævt",
"svaga",
"svága",
"svágd",
"svagg",
"svági",
"svags",
"svágt",
"Svais",
"svaka",
"svaki",
"svakt",
"svala",
"sválg",
"svali",
"svall",
"svalt",
"svalu",
"svamp",
"svana",
"svani",
"svann",
"svans",
"svara",
"svára",
"svari",
"svars",
"svart",
"svárt",
"sváru",
"svarv",
"svass",
"Sváva",
"Svávu",
"sveið",
"sveik",
"sveim",
"svein",
"sveip",
"Sveis",
"sveiv",
"svera",
"sveri",
"svert",
"svian",
"svían",
"sviar",
"svíar",
"sviða",
"svíða",
"sviði",
"sviðu",
"sviin",
"svíin",
"svika",
"svíka",
"sviki",
"svíki",
"sviks",
"svíks",
"svíkt",
"sviku",
"svíma",
"svína",
"sving",
"svíni",
"svinn",
"svins",
"svíns",
"svint",
"svíra",
"svíri",
"svirr",
"svirs",
"svítt",
"svium",
"svíum",
"sviva",
"svíva",
"svivi",
"svivs",
"svivu",
"svøku",
"svølg",
"svøll",
"svølt",
"svong",
"svørð",
"svørm",
"svørt",
"svóru",
"svøvn",
"svóvu",
"svull",
"svuls",
"svult",
"svumu",
"syðra",
"syðri",
"syðru",
"syðst",
"sýður",
"syfta",
"syfti",
"syfts",
"syftu",
"sýgur",
"sýlar",
"sýlda",
"sýldi",
"sýldu",
"sýlin",
"sýlir",
"sylla",
"syllu",
"sýlum",
"sýnað",
"sýnar",
"synda",
"sýndi",
"sýndu",
"syngi",
"sýnið",
"sýnin",
"synir",
"sýnir",
"sýnis",
"synja",
"synum",
"sýnum",
"sýpur",
"sýrað",
"sýran",
"sýrar",
"syrgt",
"Sýria",
"sýrin",
"Sýriu",
"sýrum",
"sýrur",
"sýsað",
"sýsan",
"sýsar",
"sýsla",
"sýslu",
"sýsum",
"sýsur",
"sýsut",
"sýtan",
"sýtið",
"sýtin",
"sýtir",
"sýtna",
"sýtti",
"sýttu",
"syvja",
"táari",
"taðað",
"taðar",
"taddi",
"tadna",
"tægað",
"tægan",
"tægar",
"tægra",
"tægru",
"tægur",
"tægut",
"tænið",
"tænir",
"tænti",
"tæntu",
"tærdi",
"tærdu",
"tærið",
"tærir",
"tæris",
"tærum",
"tætna",
"tætta",
"tætti",
"tættu",
"tágan",
"tágar",
"tagdi",
"tagdu",
"tagga",
"taggu",
"tágin",
"tagla",
"tagli",
"tagls",
"tagna",
"tágna",
"tagni",
"tágni",
"tágut",
"tájól",
"takið",
"takka",
"takki",
"tákna",
"táknu",
"takst",
"takta",
"takti",
"taktu",
"talað",
"talan",
"talar",
"taldi",
"taldu",
"tálga",
"talið",
"tálið",
"talja",
"talju",
"tálki",
"tálks",
"tálma",
"talum",
"talur",
"talva",
"talvi",
"talvs",
"talvu",
"támað",
"taman",
"tamar",
"támar",
"tamba",
"tambi",
"tambs",
"tamdi",
"tamdu",
"támið",
"tamil",
"tamir",
"tampa",
"tampi",
"tamps",
"tamur",
"támut",
"tánað",
"tánar",
"tandi",
"tandu",
"tanga",
"tangi",
"tangs",
"Tanja",
"Tanju",
"tanka",
"tanki",
"tanks",
"tanla",
"tanli",
"tanls",
"tanna",
"tánna",
"tánni",
"tapet",
"tapið",
"tapir",
"tappa",
"tappi",
"tapti",
"taptu",
"tapum",
"tárað",
"taran",
"tarar",
"tárar",
"tarda",
"tárið",
"tarin",
"tarma",
"tarmi",
"tarms",
"tarna",
"tarni",
"tarns",
"tartl",
"tarum",
"tárum",
"tarva",
"tarvi",
"tarvs",
"taska",
"tasku",
"tátað",
"tátan",
"tátar",
"tátíð",
"tátta",
"tátti",
"tátum",
"tátur",
"tavna",
"tegar",
"tegið",
"tegir",
"tegna",
"tegni",
"tegns",
"teiga",
"teigi",
"teigs",
"teina",
"teini",
"teins",
"teipa",
"teita",
"teiti",
"Teiti",
"Teits",
"tekan",
"tekin",
"tekir",
"tekja",
"tekju",
"tekka",
"tekki",
"Tekla",
"Teklu",
"tekna",
"tekni",
"tekns",
"tekst",
"tekti",
"tektu",
"tekum",
"tekur",
"telda",
"teldu",
"telgd",
"telgt",
"telið",
"telja",
"telji",
"telur",
"telva",
"telvi",
"telvt",
"temað",
"temba",
"tembt",
"temja",
"temna",
"tempo",
"temsa",
"temum",
"temur",
"tengd",
"tengt",
"tenja",
"tenna",
"tenor",
"tenøs",
"tenst",
"tenta",
"tenti",
"tentu",
"tenum",
"tenur",
"teori",
"teppa",
"teppi",
"tepra",
"tepri",
"tepru",
"tepti",
"teptu",
"tepur",
"Terja",
"Terji",
"terna",
"ternu",
"terpa",
"terra",
"terri",
"terta",
"terti",
"terts",
"tertu",
"terva",
"teska",
"teski",
"tesks",
"tessa",
"tetta",
"tetti",
"tettu",
"tevið",
"tevin",
"tevir",
"tevja",
"tevji",
"tevsa",
"tevur",
"teymi",
"teysa",
"tíans",
"Tibet",
"tíðað",
"tíðan",
"tíðar",
"tídda",
"tíðin",
"tíðir",
"tiðna",
"tíðum",
"tíður",
"tigið",
"tigla",
"tigli",
"tigna",
"tígna",
"tigul",
"tigur",
"tíkar",
"tikið",
"tikin",
"tíkin",
"tikna",
"tiknu",
"tíkum",
"tilið",
"tílík",
"tilja",
"tilju",
"tils.",
"tímað",
"tíman",
"tímar",
"tímdi",
"tímdu",
"tímin",
"tímir",
"tímum",
"tinað",
"tinar",
"tínar",
"tinda",
"tindi",
"tíndi",
"tinds",
"tíndu",
"tinga",
"tingi",
"tings",
"tinið",
"tínir",
"tinna",
"tinnu",
"tinta",
"tínum",
"tippa",
"tippi",
"tipta",
"tipti",
"tiptu",
"tirla",
"tirlu",
"tirra",
"tirru",
"titað",
"titan",
"titar",
"titið",
"titil",
"titla",
"titli",
"titra",
"títta",
"títtu",
"tivað",
"tivan",
"tívan",
"tivar",
"tívar",
"tivið",
"tívin",
"tívum",
"tjald",
"tjena",
"tjent",
"tjóða",
"tjógv",
"tjøld",
"tjóra",
"tjøra",
"tjørg",
"tjóri",
"tjørn",
"tjórs",
"tjøru",
"tjóva",
"tjóvi",
"tjóvs",
"tjuff",
"tjúgu",
"tjúkd",
"tjúkk",
"tjúkn",
"tjúkt",
"tjums",
"tjykd",
"tóast",
"toddi",
"tøðið",
"tódna",
"tøðum",
"tofta",
"Tofta",
"toftu",
"togað",
"togan",
"togar",
"tøggi",
"togið",
"togna",
"togni",
"togum",
"tógva",
"tógvi",
"tokað",
"tokan",
"tøkan",
"tokar",
"tøkar",
"tokið",
"tøkir",
"tokka",
"tokki",
"tokna",
"tøkni",
"tókst",
"tókti",
"tóktu",
"tokum",
"tøkum",
"tøkur",
"tokut",
"Tokyo",
"tolað",
"tølað",
"toldi",
"tøldi",
"toldu",
"tøldu",
"tolið",
"tólið",
"tølið",
"tolin",
"tolir",
"tølir",
"tølis",
"tolka",
"tolki",
"tolks",
"tolla",
"tolli",
"tolna",
"tolni",
"tølsk",
"tolug",
"tólum",
"tølum",
"tølur",
"tólva",
"tólvi",
"tólvs",
"tóman",
"tómar",
"tomat",
"tomba",
"tombu",
"tømdi",
"tømdu",
"tømið",
"tómin",
"tómir",
"tømir",
"tómló",
"tomum",
"tómum",
"tomur",
"tómur",
"tónað",
"tónan",
"tónar",
"Tonga",
"tongt",
"tónin",
"tónir",
"tonkt",
"tonsa",
"tonsi",
"tónum",
"tonut",
"toppa",
"tøppa",
"toppi",
"tøppi",
"toppu",
"torað",
"tórað",
"toran",
"tordi",
"Tórði",
"tordu",
"torga",
"torgi",
"torgs",
"torir",
"torna",
"tørna",
"torni",
"tørni",
"torns",
"tørns",
"Torný",
"torpa",
"torpi",
"torps",
"torra",
"torri",
"torti",
"torts",
"Tórur",
"torva",
"tørva",
"torvi",
"tørvi",
"torvs",
"tørvs",
"torvu",
"tosað",
"tosan",
"tosar",
"tosið",
"toska",
"toski",
"tosks",
"tøssa",
"tøssi",
"tosta",
"tosti",
"totað",
"tøtað",
"totar",
"tøtar",
"totið",
"tøtir",
"tøtla",
"tøtli",
"tøtls",
"tøtti",
"tøttu",
"totut",
"tóvað",
"tóvan",
"tøvan",
"tóvar",
"tøvdi",
"tøvdu",
"tóvið",
"tóvin",
"tøvir",
"tóvum",
"tøvur",
"toyað",
"toyál",
"toyar",
"toyga",
"toygd",
"toygi",
"toygs",
"toygt",
"toyið",
"toyma",
"toymi",
"toyta",
"toytt",
"toyur",
"tráað",
"tráan",
"tráar",
"traða",
"tráða",
"tráði",
"traðk",
"tráðu",
"træða",
"træið",
"træla",
"træli",
"træll",
"træls",
"træna",
"trænu",
"træta",
"trætt",
"trætu",
"traff",
"tráið",
"tráin",
"tráir",
"trakt",
"trall",
"trals",
"trama",
"trami",
"tramp",
"trana",
"trána",
"trani",
"trant",
"trask",
"trast",
"trátt",
"tráum",
"tráur",
"treða",
"trega",
"tregi",
"trein",
"treiv",
"treka",
"treki",
"trekk",
"treks",
"trekt",
"treku",
"trepa",
"trepi",
"treyð",
"treyt",
"triði",
"Trina",
"trína",
"trini",
"tríni",
"trinn",
"trins",
"trint",
"trinu",
"Trinu",
"trist",
"tritl",
"tríum",
"tríur",
"triva",
"tríva",
"trivi",
"trívi",
"trivs",
"trivu",
"troða",
"trøða",
"trodd",
"tróðu",
"troga",
"trogi",
"trogs",
"troka",
"troki",
"troks",
"trola",
"troli",
"trøll",
"trols",
"trøls",
"troma",
"tróna",
"Trónd",
"trong",
"tróni",
"tróns",
"trónu",
"tropa",
"trósk",
"trøst",
"trota",
"tróta",
"troti",
"tróti",
"trots",
"tróts",
"trøtt",
"trótu",
"trøum",
"trúan",
"trúar",
"trúði",
"trúðu",
"trúga",
"trúgv",
"trúið",
"trúin",
"trúir",
"trukk",
"truks",
"trumm",
"trums",
"trúna",
"trúni",
"trunk",
"trust",
"trúti",
"trúts",
"trútt",
"trutu",
"trúum",
"trúur",
"trygd",
"trygg",
"trygt",
"trykk",
"trykt",
"trylt",
"trýna",
"trýni",
"trýrt",
"trýss",
"trýst",
"tubba",
"tuffi",
"tufla",
"tuflu",
"tugda",
"tugdi",
"tugdu",
"tugga",
"tuggu",
"tugla",
"túgva",
"túgvu",
"tukta",
"tulka",
"tulki",
"tulks",
"túlli",
"tulpa",
"tulpu",
"tumla",
"tumli",
"tumma",
"tummi",
"tunga",
"tungi",
"tungt",
"tungu",
"túnið",
"Tunis",
"tunla",
"tunli",
"tunna",
"tunni",
"tunnu",
"túnum",
"tupan",
"tupum",
"tupur",
"túrað",
"túrar",
"Turið",
"túrin",
"túrir",
"turka",
"turki",
"turks",
"turna",
"turni",
"turns",
"turra",
"turri",
"turru",
"túrum",
"túrur",
"turva",
"turvi",
"turvt",
"tuska",
"tuski",
"tusks",
"tusna",
"túsna",
"tussa",
"tussi",
"tussj",
"tusti",
"tustu",
"tútað",
"tútan",
"tútar",
"tútin",
"tutla",
"tutli",
"tutls",
"tútum",
"tútur",
"túvan",
"túvum",
"túvur",
"túvut",
"tváað",
"tváar",
"tvaði",
"tvaðs",
"tvætl",
"tvæts",
"tvætt",
"tvaga",
"tvagi",
"tvags",
"tváið",
"tvara",
"tvari",
"tvarr",
"tvars",
"tvass",
"tvátt",
"tvein",
"tveir",
"tveit",
"tveyu",
"tvíað",
"tvíar",
"tvíár",
"tvinn",
"tvint",
"tvist",
"tvíta",
"tvítt",
"tvøga",
"tvøgu",
"tvong",
"tvøra",
"tvørá",
"tvøri",
"tvørs",
"tvørt",
"tvøru",
"tvøst",
"týðað",
"týðan",
"týðar",
"týdda",
"týddi",
"týddu",
"týðir",
"týðum",
"týður",
"tyfus",
"týfus",
"týggi",
"týggj",
"tygil",
"tygum",
"tykir",
"tykja",
"tykki",
"tykna",
"tykni",
"tykta",
"tykti",
"tykur",
"tylft",
"tylvt",
"týndi",
"týndu",
"tyngd",
"tyngt",
"týnir",
"tynka",
"tynna",
"tynra",
"tynri",
"tynru",
"tynta",
"tynti",
"tyntu",
"typan",
"typum",
"typur",
"tyril",
"tyrla",
"tyrli",
"tyrlu",
"tyrpa",
"tyrpt",
"Týrur",
"tyrva",
"tyrvd",
"tyrvi",
"tyrvt",
"tysja",
"týska",
"týski",
"týsks",
"týskt",
"týsku",
"týsna",
"tyssa",
"tyssi",
"tysta",
"tysti",
"tystu",
"tysur",
"tytta",
"tyttu",
"uddur",
"uggað",
"uggan",
"uggar",
"uggið",
"uggin",
"uggum",
"uglan",
"uglum",
"uglur",
"úldað",
"úldar",
"úldna",
"úldur",
"ulkan",
"ulkum",
"ulkur",
"ullað",
"ullar",
"ullin",
"ullir",
"ullum",
"úlmað",
"úlmar",
"úlnar",
"ulpan",
"úlpan",
"ulpum",
"úlpum",
"ulpur",
"úlpur",
"úlvar",
"úlvin",
"úlvsa",
"úlvsi",
"úlvum",
"úlvur",
"umbar",
"umber",
"umboð",
"umbøn",
"umbót",
"umdur",
"umenn",
"umfar",
"umfør",
"ummað",
"ummál",
"ummar",
"umráð",
"umsat",
"umsit",
"umtak",
"umtal",
"umtøk",
"umvæl",
"umval",
"umvav",
"umveg",
"undan",
"undir",
"undra",
"undri",
"undur",
"ungað",
"ungan",
"ungar",
"ungin",
"ungir",
"ungum",
"ungur",
"unión",
"unnað",
"unnar",
"Unnar",
"unnir",
"Unnur",
"untur",
"uppás",
"uppat",
"uppiá",
"uppií",
"uppum",
"uppúr",
"upsan",
"upsar",
"upsin",
"upsir",
"upsum",
"úraði",
"úraðu",
"urani",
"urans",
"urðar",
"urðin",
"urðum",
"urgan",
"urgum",
"urgur",
"urini",
"úrlík",
"urnan",
"urnar",
"urnið",
"urpta",
"úrrit",
"ursan",
"ursar",
"ursin",
"ursum",
"úrtak",
"urtar",
"úrtíð",
"urtin",
"urtir",
"úrtøk",
"urtum",
"úrval",
"útætt",
"útása",
"útblá",
"útboð",
"útbúð",
"útbýa",
"útbýi",
"útbýr",
"útdyr",
"úteld",
"útelt",
"útfit",
"útfrá",
"útham",
"úthav",
"úthøv",
"úthug",
"úthús",
"útint",
"útlán",
"útlát",
"útlið",
"útlim",
"útlit",
"útlop",
"útmat",
"útmið",
"útnes",
"útrak",
"útrák",
"útras",
"útriv",
"útrøs",
"útrun",
"útsig",
"útsúg",
"útsýn",
"úttak",
"uttan",
"uttar",
"úttøk",
"úttrá",
"útveg",
"útvið",
"vaðað",
"váðan",
"vaðar",
"váðar",
"vaðið",
"váðin",
"váðir",
"vaðum",
"váðum",
"vælan",
"vælar",
"vælið",
"vælin",
"vælir",
"vælum",
"vænan",
"vænir",
"vænta",
"vænti",
"væntu",
"vænur",
"værið",
"væris",
"vætað",
"vætan",
"vætar",
"vætið",
"vætir",
"vætti",
"vættu",
"vætum",
"vætur",
"vafla",
"vaflu",
"vágað",
"vágan",
"vágar",
"Vágar",
"vágdi",
"vágdu",
"vagga",
"vágið",
"vágin",
"vágir",
"Vágir",
"vagla",
"vagli",
"vagls",
"vagna",
"Vagna",
"vágna",
"vagni",
"Vagni",
"vágni",
"vagns",
"Vagns",
"Vagnu",
"Vágoy",
"vágum",
"Vágum",
"vágur",
"Vágur",
"vakdi",
"vakdu",
"vakið",
"vakin",
"vakir",
"vakja",
"vakna",
"vákna",
"vakni",
"vákni",
"vákns",
"vaknu",
"vakra",
"vakri",
"vaksa",
"vaksi",
"vakta",
"vakti",
"vaktu",
"vakur",
"válað",
"válan",
"valár",
"válar",
"valda",
"valdi",
"valds",
"valdu",
"valgi",
"valgs",
"valið",
"válið",
"valin",
"válin",
"valir",
"válir",
"válka",
"válki",
"válks",
"valla",
"valli",
"valna",
"valsa",
"valsi",
"valum",
"válum",
"valur",
"válur",
"vamba",
"vamla",
"vamli",
"vamls",
"vampa",
"vampi",
"vanan",
"vanar",
"vanda",
"vánda",
"vandi",
"vandt",
"vandu",
"vanga",
"vangi",
"vanin",
"vanir",
"vánir",
"vanki",
"vanks",
"vánna",
"vánni",
"vansa",
"vansi",
"vanst",
"vanta",
"vanti",
"vants",
"vanum",
"vánum",
"vanur",
"vápna",
"vápni",
"vápns",
"varað",
"varan",
"varar",
"várar",
"Várar",
"varda",
"varða",
"vardi",
"varði",
"vardu",
"varga",
"vargi",
"vargs",
"varið",
"várið",
"varig",
"varin",
"varir",
"varma",
"varmi",
"varmt",
"varna",
"varni",
"varnu",
"varpa",
"varpi",
"varra",
"varta",
"varug",
"varum",
"várum",
"varur",
"vasað",
"vasan",
"vasar",
"vasið",
"vásið",
"vasin",
"vaska",
"vaski",
"vasks",
"vaskt",
"vassa",
"vasum",
"vasut",
"vátan",
"vátar",
"vátir",
"vatna",
"vatni",
"vatns",
"vatta",
"vátta",
"vatti",
"vátti",
"vátum",
"vátur",
"vavdi",
"vavdu",
"vavið",
"vavva",
"vavvi",
"vedda",
"veddi",
"veðið",
"veðra",
"veðri",
"veðum",
"veður",
"vefta",
"vefti",
"vefts",
"vegað",
"vegar",
"veggi",
"vegin",
"vegir",
"vegna",
"vegum",
"vegur",
"veiða",
"veidd",
"veiði",
"veiðu",
"veigs",
"veiið",
"veika",
"veiki",
"veiks",
"veikt",
"veiku",
"veila",
"veilt",
"veilu",
"veipa",
"veipi",
"veips",
"veira",
"veiri",
"veirs",
"veita",
"veiti",
"veitt",
"veium",
"vekið",
"vekir",
"vekja",
"vekra",
"vekri",
"veksa",
"vekst",
"vekta",
"vekur",
"velda",
"veldi",
"veldu",
"velið",
"velja",
"velji",
"velkt",
"vella",
"velta",
"velti",
"veltu",
"velum",
"velur",
"venað",
"venan",
"venar",
"venda",
"vendi",
"vendu",
"venja",
"venji",
"venju",
"venur",
"veran",
"verda",
"verða",
"verði",
"verið",
"verja",
"verji",
"verju",
"verka",
"verki",
"verks",
"verkt",
"verma",
"vermt",
"vernd",
"verpa",
"verra",
"verri",
"verru",
"versa",
"versi",
"verst",
"verta",
"verti",
"verts",
"verum",
"verur",
"vesæl",
"vesal",
"vesan",
"vesið",
"vesir",
"vesis",
"vespa",
"vespu",
"vessa",
"vessi",
"vesta",
"vesti",
"vests",
"vesum",
"vetað",
"vetar",
"vetið",
"vetna",
"vetni",
"vetra",
"vetri",
"vetur",
"vevað",
"vevar",
"vevin",
"vevir",
"vevja",
"vevna",
"vevni",
"vevum",
"vevur",
"víðan",
"viðar",
"víðar",
"vídda",
"video",
"viðin",
"víðir",
"viðja",
"viðju",
"víðka",
"Viðoy",
"viðra",
"víðum",
"viður",
"víður",
"viðv.",
"vifta",
"viftu",
"vigað",
"vigar",
"vígda",
"vígdi",
"vígdu",
"víggi",
"víggj",
"Viggo",
"vigið",
"vígið",
"vigin",
"vígir",
"vigla",
"vigli",
"vignu",
"vígum",
"vikað",
"vikan",
"vikar",
"víkar",
"Víkar",
"vikið",
"vikin",
"víkin",
"víkir",
"víkja",
"vikna",
"víkti",
"víktu",
"vikum",
"víkum",
"vikur",
"víkur",
"víkut",
"vildi",
"vildu",
"vilja",
"vilji",
"villa",
"villi",
"villu",
"vilst",
"vilta",
"vilti",
"viltu",
"vilum",
"vimpa",
"vimsa",
"vimsi",
"vinan",
"vinar",
"vinda",
"vindi",
"vinds",
"vindu",
"vingl",
"vínið",
"vinin",
"vinir",
"vinka",
"vinki",
"vinks",
"vinna",
"vinni",
"vinnu",
"Vinný",
"vinst",
"vinum",
"vínum",
"vinur",
"vinyl",
"vípan",
"vippa",
"vippu",
"vípum",
"vípur",
"virda",
"virða",
"virdi",
"virði",
"virdu",
"vírik",
"virka",
"virki",
"virkt",
"virus",
"vísan",
"vísar",
"visið",
"vísið",
"visin",
"vísir",
"viska",
"viski",
"visks",
"visna",
"vissa",
"vissi",
"vissu",
"vista",
"vísta",
"visti",
"vísti",
"vistu",
"vístu",
"vísum",
"vísur",
"vitað",
"vitan",
"vitar",
"vitið",
"vitig",
"vitin",
"vitja",
"vitji",
"vitna",
"vitni",
"vitra",
"vitug",
"vitum",
"vitur",
"Vivia",
"vívið",
"Viviu",
"vívum",
"vødda",
"vøddi",
"vodka",
"vøðum",
"voffa",
"voffi",
"vøgga",
"vøggu",
"vogna",
"vógna",
"vogni",
"vogns",
"vøkan",
"vøkru",
"voksi",
"vøkst",
"vøkum",
"vøkur",
"volað",
"vølan",
"volar",
"vølar",
"volda",
"voldi",
"voldu",
"Volga",
"volið",
"vølir",
"vølli",
"volta",
"volti",
"volts",
"volum",
"vølum",
"vølur",
"vølva",
"Volvo",
"vølvu",
"vónað",
"vónar",
"vonda",
"vondu",
"vongi",
"vongs",
"vónin",
"vónir",
"vonum",
"vónum",
"vøran",
"vórða",
"vørða",
"vorði",
"vørði",
"vórðu",
"vørðu",
"vorið",
"vørpa",
"vørpu",
"vørri",
"vørta",
"vørtu",
"vorum",
"vørum",
"vørur",
"vøsað",
"vøsan",
"vøsar",
"vøsið",
"vøsum",
"vøsut",
"vøtti",
"vovið",
"vovin",
"vovna",
"vovnu",
"vovva",
"vovvi",
"vráar",
"vráin",
"vráir",
"vraka",
"vraki",
"vraks",
"vráum",
"vreið",
"vrevl",
"vriða",
"vuksu",
"vundu",
"vunið",
"vunna",
"vunnu",
"vurpu",
"y-ása",
"yddað",
"yddar",
"ýddur",
"yðjan",
"ýðjdi",
"ýðjdu",
"ýðjir",
"Yemen",
"ýldur",
"ýling",
"ýlini",
"ylnað",
"ylnar",
"ymisk",
"ymiss",
"ymist",
"ymsan",
"ymsar",
"ymsir",
"ymsum",
"yndað",
"yndar",
"yndið",
"yndig",
"yndis",
"yndug",
"yngda",
"yngdi",
"yngdu",
"yngir",
"yngja",
"yngla",
"yngli",
"yngra",
"yngri",
"yngru",
"yngst",
"yngul",
"Yngva",
"Yngvu",
"ynnað",
"ynnar",
"ynski",
"ynskt",
"yppað",
"yppar",
"yppir",
"yptur",
"yrkið",
"yrkir",
"yrkis",
"yrkja",
"yrkti",
"yrktu",
"ýsopi",
"ýsopp",
"ýsops",
"ystir",
"ystur",
"ýtaði",
"ýtaðu",
"ýtini",
"ytsta",
"ytsti",
"ytstu",
"ýttur",
"ýtuna",
"ýtuni",
"ývaði",
"ývaðu",
"ývdur",
"yviri",
"ývnað",
"ývnan",
"ývnar",
"ývnir",
"ývnum",
"yvris"
]
