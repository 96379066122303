export const WORDLE_TITLE = 'ð.fo - git dagsins orð'

export const WIN_MESSAGES = ['Frálíkt!', 'Øgiligt', 'Sera gott!']
export const GAME_COPIED_MESSAGE = 'Avritað til klippiborðið'
export const ABOUT_GAME_MESSAGE = 'Um hetta spælið'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Ov fáir stavir'
export const WORD_NOT_FOUND_MESSAGE = 'Orðið ikki funnið'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Orðið var ${solution}`
export const ENTER_TEXT = 'Send'
export const STATISTICS_TITLE = 'Hagtøl'
export const GUESS_DISTRIBUTION_TEXT = 'Hvussu nógvar royndir áðrenn rætt svar?'
export const NEW_WORD_TEXT = 'Nýtt orð tøkt'
export const SHARE_TEXT = 'Deil spæl'
export const TOTAL_TRIES_TEXT = 'Royndir íalt'
export const SUCCESS_RATE_TEXT = 'Hvussu ofta rætt'
export const CURRENT_STREAK_TEXT = 'Núverandi røtt á rað'
export const BEST_STREAK_TEXT = 'Flest røtt á rað higartil'
