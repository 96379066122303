import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Stutt vegleiðing" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Git dagsins orð. Tú kanst gita í mesta lagi 6 ferðir. Tá ið tú gitir, so broytir teksturin lit, alt eftir hvussu rætt gitið var.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="K" status="correct" />
        <Cell value="E" />
        <Cell value="T" />
        <Cell value="T" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Bókstavurin K er í orðinum og hann er á rætta stað.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" />
        <Cell value="I" />
        <Cell value="L" status="present" />
        <Cell value="U" />
        <Cell value="R" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Bókstavurin L er í orðinum, men hann er ikki á rætta stað.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="K" />
        <Cell value="V" />
        <Cell value="Æ" />
        <Cell value="Ð" status="absent" />
        <Cell value="I" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Bókstavurin Ð er yvirhøvur ikki í orðinum.
      </p>
    </BaseModal>
  )
}
