import { KeyValue } from '../../lib/keyboard'
import { getStatuses } from '../../lib/statuses'
import { Key } from './Key'
import { useEffect, useState } from 'react'
import { ENTER_TEXT } from '../../constants/strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackspace } from '@fortawesome/free-solid-svg-icons'

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  guesses: string[]
}

export const Keyboard = ({ onChar, onDelete, onEnter, guesses }: Props) => {
  const charStatuses = getStatuses(guesses)
  const [ accent, setAccent ] = useState(false)

  const onClick = (value: KeyValue) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
    }
  }

  useEffect(() => {
    const validkeys = ['E','R','T','Y','Ý','U','Ú','I','Í','O','Ó','P','A','Á','S','D','Ð','F','G','H','J','K','L','Æ','Ø','V','B','N','M']
    const accentkeys = ['Y','U','I','O','A']
    const convertkeys = ['Ý','Ú','Í','Ó','Á']
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
      } else if (e.code === 'Equal') {
        setAccent(!accent)
      }
       else {
        let key = e.key.toUpperCase()
        if (accentkeys.includes(key) && accent ) {
          key = convertkeys[accentkeys.indexOf(key)]
          setAccent(false)
        }
        if (key.length === 1 && validkeys.includes(key)) {
          onChar(key)
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar, accent])

  return (
    <div>
      <div className="flex justify-center mb-1">
        <Key value="E" onClick={onClick} status={charStatuses['E']} />
        <Key value="R" onClick={onClick} status={charStatuses['R']} />
        <Key value="T" onClick={onClick} status={charStatuses['T']} />
        <Key value="Y" onClick={onClick} status={charStatuses['Y']} />
        <Key value="Ý" onClick={onClick} status={charStatuses['Ý']} />
        <Key value="U" onClick={onClick} status={charStatuses['U']} />
        <Key value="Ú" onClick={onClick} status={charStatuses['Ú']} />
        <Key value="I" onClick={onClick} status={charStatuses['I']} />
        <Key value="Í" onClick={onClick} status={charStatuses['Í']} />
        <Key value="O" onClick={onClick} status={charStatuses['O']} />
        <Key value="Ó" onClick={onClick} status={charStatuses['Ó']} />
        <Key value="P" onClick={onClick} status={charStatuses['P']} />
      </div>
      <div className="flex justify-center mb-1">
      <Key value="A" onClick={onClick} status={charStatuses['A']} />
        <Key value="Á" onClick={onClick} status={charStatuses['Á']} />
        <Key value="S" onClick={onClick} status={charStatuses['S']} />
        <Key value="D" onClick={onClick} status={charStatuses['D']} />
        <Key value="Ð" onClick={onClick} status={charStatuses['Ð']} />
        <Key value="F" onClick={onClick} status={charStatuses['F']} />
        <Key value="G" onClick={onClick} status={charStatuses['G']} />
        <Key value="H" onClick={onClick} status={charStatuses['H']} />
        <Key value="J" onClick={onClick} status={charStatuses['J']} />
        <Key value="K" onClick={onClick} status={charStatuses['K']} />
        <Key value="L" onClick={onClick} status={charStatuses['L']} />
      </div>
      <div className="flex justify-center">
        <Key width={65.4} value="ENTER" onClick={onClick} status="red">
          {ENTER_TEXT}
        </Key>
        <Key value="V" onClick={onClick} status={charStatuses['V']} />
        <Key value="B" onClick={onClick} status={charStatuses['B']} />
        <Key value="N" onClick={onClick} status={charStatuses['N']} />
        <Key value="M" onClick={onClick} status={charStatuses['M']} />
        <Key value="Æ" onClick={onClick} status={charStatuses['Æ']} />
        <Key value="Ø" onClick={onClick} status={charStatuses['Ø']} />
        <Key width={65.4} value="DELETE" onClick={onClick} status="red">
          <FontAwesomeIcon icon={faBackspace} />
        </Key>
      </div>
    </div>
  )
}
