import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Um ð.fo" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        ð.fo er eitt føroyskt avbrigdi av klonaðu útgávuni hjá Hannah Park av spælinum Wordle.{' '}
        <a
          href="https://tokni.fo"
          className="underline font-bold"
        >
          Tökni
        </a>
        {' '}hevur føroyskað.{' '}
        Keldukotan og upplýsingar um rættindir eru tøk á{' '}
        <a
          href="https://github.com/hannahcode/wordle"
          className="underline font-bold"
        >
          github.com
        </a>
        .{' '}
        Føroysku orðini eru úr einum gomlum orðalista hjá{' '}
        <a
          href="https://www.frodskaparfelag.fo/grunnar/ordabokagrunnurin/"
          className="underline font-bold"
        >
          Orðabókagrunninum
        </a>
        .{' '}
        Upprunaspælið við enskum orðum er tøkt á{' '}
        <a
          href="https://www.powerlanguage.co.uk/wordle/"
          className="underline font-bold"
        >
          powerlanguage.co.uk
        </a>
      </p>
    </BaseModal>
  )
}
